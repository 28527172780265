import { render, staticRenderFns } from "./Threshold.vue?vue&type=template&id=38d19152&scoped=true&"
import script from "./Threshold.vue?vue&type=script&lang=js&"
export * from "./Threshold.vue?vue&type=script&lang=js&"
import style0 from "./Threshold.vue?vue&type=style&index=0&id=38d19152&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d19152",
  null
  
)

export default component.exports
<template>
  <div class="all-height all-width">
    <div class="">
      <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex mb-0">
              <div class="d-flex align-center">
                <span class="display-1">{{formatedData.length}}</span>
                <span class="ml-2 line-1">Reporting Units<br />Count</span>
              </div>
              <v-spacer></v-spacer>
              <v-tooltip bottom content-class='tooltip-bottom'>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" name="faq" @click.stop="faqdialog = true;" icon small>
                    <v-icon>mdi-comment-question-outline</v-icon></v-btn>
                </template>
                <span>FAQ</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-0 px-0" icon v-on="on" @click="refreshData()">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </div>
            <lb-datatablenew :headers=" headers" :items="formatedData" :enableslot="['action']" :loading="loading"
              multiselectkey="_id" v-if="data.length > 0" :selecteditems="[]">
              <template v-slot:additionalinfo="{ item, selected, selectall }">
                <v-tooltip bottom content-class="tooltip-bottom" v-if="selectall || selected.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="commentaryMaterialityActual"
                      @click="editData(item, 'Actual', 'bulk', selected)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk Update for Actual Cycle</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom" v-if="selectall || selected.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="commentaryMaterialityForecast"
                      @click="editData(item, 'Forecast', 'bulk', selected)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk Update for Forecast Cycle</span>
                </v-tooltip>
                <div class="text-right">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="pendingdialog=true"
                        :disabled="pendingrequestlist.length === 0">
                        <v-icon>mdi-timer-sand</v-icon>
                      </v-btn>
                    </template>
                    <span>View pending approval</span>
                  </v-tooltip>
                </div>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                      @click.stop="editData(item, 'Both', 'rufile', [])">
                      <v-icon>mdi-file-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk RU level upload</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on"
                      @click.stop="editData(item, 'Both', 'glfile', [])">
                      <v-icon>mdi-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk GL level upload</span>
                </v-tooltip>
              </template>
              <template v-slot:action="{ item }">
                <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="commentaryMaterialityActual"
                      @click="editData(item, 'Actual', 'single', item._id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update for Actual Cycle</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" color="commentaryMaterialityForecast"
                      @click="editData(item, 'Forecast', 'single', item._id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update for Forecast Cycle</span>
                </v-tooltip>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on"
                      @click="editData(item, 'Actual', 'single', item._id,true)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View materiality</span>
                </v-tooltip>
              </template>
            </lb-datatablenew>
            <div v-else-if='loading' class="title grey--text pa-5 text-center">
              Please wait as we load your data
            </div>
            <div v-else class="title grey--text pa-5 text-center">
              No Data Found
            </div>
          </div>
        </v-card-text>
      </v-card>

      <lb-dialog v-model="bulksubmitrudialog" heading="Bulk Upload RU Level Materiality"
        :width="(bulksubmitrufiledata.length > 0)?'':'500'" :loading="bulksubmitruloading">
        <template v-slot:body>
          <div>
            <div class="mb-2 d-flex align-center justify-center">
              <v-btn color="primary" small class="mr-2"
                @click="$nova.downloadFile('', {}, '/v2/varianceanalysis/materiality/getbulkrusubmittemplate')">Download
                Template</v-btn>
              <lb-file v-model="bulksubmitrufile" label="" class="py-0 my-0" :loading="fileprocessing['rufile'] === 1"
                :drag="false" hidedetails @change="readBulkSubmitFile('rufile')" :displayname="false"
                accept=".xlsx,.xls,.xlsm,.xlsb" />
            </div>
            <div v-if="bulksubmitrufiledata.length > 0">
              <div class="d-flex">
                <!-- <lb-selectmultiplemonths type="month" hidedetails v-model="addeditrudata.periods" label="Applicable Periods"
                  id="applicable-preiods " class='ml-2' :min='minDate' :max="maxDate" :key="new Date().getTime()"/> -->
              </div>
               <div v-if="(addeditrudata?.periods || []).length" class="selected-months mb-3">
                <h3>Selected Months:</h3>
                <ul>
                <li v-for="(month, index) in addeditrudata?.periods" :key="index" class=' month-item'>
                    {{ month }}
                      <!-- <v-chip small label class="ml-2">{{ month}}</v-chip> -->
                    <v-btn small icon elevation="plain" @click="removeMonth(month)">
                    <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </li>
                </ul>
            </div>
              
              <div class="mb-1">Note: <br>1.&nbsp; All numbers reported in the Commentary Application are in USD
                thousands (For instance, entering an amount/percentage of "30" corresponds to USD 30,000).
                <br> 2.&nbsp; The Local and Regional Control percantages are set to 999999% by default and cannot be
                edited.
                <br> 3.&nbsp;Materiality can be updated only for the current financial year.
                <br> 4.&nbsp;To update materiality at GL level for multiple periods , please use the bulk file upload option.
              </div>
              <v-simple-table dense class="v-table-color">
                <thead>
                  <tr>
                    <th scope="rucode" rowspan="3" class="">RU Code</th>
                    <th scope="runame" rowspan="3" class="">RU Name</th>
                    <th scope="period" rowspan="3" class="">Period</th>
                    <th scope="actual_cycle" colspan="16" class="">Actual Cycle</th>
                    <th scope="forecast_cycle" colspan="16" class="">Forecast Cycle</th>
                  </tr>
                  <tr>
                    <th scope="a_local_cycle" colspan="8" class="">Actual Vs Proforma</th>
                    <th scope="a_regional_cycle" colspan="8" class="">Actual Vs Forecast</th>
                    <th scope="f_local_cycle" colspan="8" class="">Forecast Vs Proforma</th>
                    <th scope="f_regional_cycle" colspan="8" class="">Forecast Vs Previous Forecast</th>
                  </tr>
                  <tr>
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                  </tr>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, k) in bulksubmitrufiledata" :key="k" class="">
                    <template v-for="(vv, kk) in bulksubmitrufilecolumns">
                      <td v-if="bulksubmitrufileerror[k][vv]" :key="kk" class="error--text font-weight-bold">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" v-if="vv==='rucode' || vv === 'period'">{{v[vv] || bulksubmitglfileerror[k][vv] || ''}}</span>
                            <span v-bind="attrs" v-on="on" v-else>{{bulksubmitrufileerror[k][vv] || 'Error'}}</span>
                          </template>
                          <span>{{ bulksubmitrufileerror[k][vv] }}</span>
                        </v-tooltip>
                      </td>
                      <td v-else :key="kk">
                        <div :id="kk">{{ v[vv] || '' }}</div>
                      </td>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </v-simple-table>
            </div>
            <div v-else class="grey--text pa-6 text-center title">
              Upload template file
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="bulksubmitrufileerrorflag" class="error--text font-weight-bold">
            Please add correct value
          </div>
          <span v-else-if="bulksubmitrufiledata.length > 0" class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn v-if="bulksubmitrufiledata.length > 0 && !bulksubmitrufileerrorflag"
            @click="updateThreshold()" color="primary" small class="ml-2 shadow-off">Proceed to Upload</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="bulksubmitgldialog" heading="Bulk Upload GL Level Materiality"
        :width="(bulksubmitglfiledata.length > 0)?'':'500'" :loading="bulksubmitglloading">
        <template v-slot:body>
          <div>
            <div class="mb-2 d-flex align-center justify-center">
              <v-btn color="primary" small class="mr-2"
                @click="$nova.downloadFile('', {}, '/v2/varianceanalysis/materiality/getbulkglsubmittemplate')">Download
                Template</v-btn>
              <lb-file v-model="bulksubmitglfile" label="" class="py-0 my-0" :loading="fileprocessing['rufile'] === 1"
                :drag="false" hidedetails @change="readBulkSubmitFile('glfile')" :displayname="false"
                accept=".xlsx,.xls,.xlsm,.xlsb" />
            </div>
            <div v-if="bulksubmitglfiledata.length > 0">
              <!-- <div class="d-flex">
                <lb-selectmultiplemonths type="month" hidedetails v-model="addeditrudata.periods" label="Applicable Periods"
                  id="applicable-preiods " class='ml-2' :min='minDate' :max="maxDate" :key="new Date().getTime()"/>
              </div> -->
              <div v-if="(addeditrudata?.periods || []).length" class="selected-months mb-3">
                <h3>Selected Months:</h3>
                <ul>
                <li v-for="(month, index) in addeditrudata?.periods" :key="index" class=' month-item'>
                    {{ month }}
                      <!-- <v-chip small label class="ml-2">{{ month}}</v-chip> -->
                    <v-btn small icon elevation="plain" @click="removeMonth(month)">
                    <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </li>
                </ul>
            </div>
              <div class="mb-1">Note: <br>1.&nbsp; All numbers reported in the Commentary Application are in USD
                thousands (For instance, entering an amount/percentage of "30" corresponds to USD 30,000).
                <br> 2.&nbsp; The Local and Regional Control percantages are set to 999999% by default and cannot be
                edited.
                <br> 3.&nbsp;Materiality can be updated only for the current financial year.
                <br> 4.&nbsp;To update materiality at GL level for multiple periods , please use the bulk file upload option.
              </div>
              <v-simple-table dense class="v-table-color">
                <thead>
                  <tr>
                    <th scope="rucode" rowspan="4" class="">RU Code</th>
                    <th scope="runame" rowspan="4" class="">RU Name</th>
                    <th scope="glcode" rowspan="4" class="">GL Code</th>
                    <th scope="glname" rowspan="4" class="">GL Name</th>
                    <th scope="period" rowspan="4" class="">Period</th>
                    <th scope="actual_cycle" colspan="16" class="">Actual Cycle</th>
                    <th scope="forecast_cycle" colspan="16" class="">Forecast Cycle</th>
                  </tr>
                  <tr>
                    <th scope="a_local_cycle" colspan="8" class="">Actual Vs Proforma</th>
                    <th scope="a_regional_cycle" colspan="8" class="">Actual Vs Forecast</th>
                    <th scope="f_local_cycle" colspan="8" class="">Forecast Vs Proforma</th>
                    <th scope="f_regional_cycle" colspan="8" class="">Forecast Vs Previous Forecast</th>
                  </tr>
                  <tr>
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="l_ctrl_amt" class="">LC Amt</th>
                    <th scope="l_ctrl_amt" class="">LC%</th>
                    <!-- <th scope="l_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="l_mngt_amt" class="">LM Amt</th>
                    <th scope="l_mngt_amt" class="">LM %</th>
                    <!-- <th scope="l_mngt_famt" class="">Mngt FAmt</th> -->
                    <th scope="r_ctrl_amt" class="">RC Amt</th>
                    <th scope="r_ctrl_amt" class="">RC %</th>
                    <!-- <th scope="r_ctrl_famt" class="">Ctrl FAmt</th> -->
                    <th scope="r_mngt_amt" class="">RM Amt</th>
                    <th scope="r_mngt_amt" class="">RM %</th>
                    <!-- <th scope="r_mngt_famt" class="">Mngt FAmt</th> -->
                  </tr>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, k) in bulksubmitglfiledata" :key="k" class="">
                    <template v-for="(vv, kk) in bulksubmitglfilecolumns">
                      <td v-if="bulksubmitglfileerror[k][vv]" :key="kk" class="error--text font-weight-bold">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" v-if="vv==='glcode' || vv==='rucode' || vv==='period'">{{v[vv] || bulksubmitglfileerror[k][vv] ||
                              ''}}</span>
                            <span v-bind="attrs" v-on="on" v-else>{{bulksubmitglfileerror[k][vv] || 'Error'}}</span>
                          </template>
                          <span>{{ bulksubmitglfileerror[k][vv]}}</span>
                        </v-tooltip>
                      </td>
                      <td v-else :key="kk">
                        <div>{{ v[vv] || '' }}</div>
                      </td>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </v-simple-table>
            </div>
            <div v-else class="grey--text pa-6 text-center title">
              Upload template file
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="emptyRuCode" class="error--text pr-4 font-weight-bold">
            Ru code cannot be empty, Please provide valid value
          </div>
          <div v-if="bulksubmitglfileerrorflag=== true && emptyRuCode===false" class="error--text font-weight-bold">
            Please provide valid value
          </div>
          <span v-else-if="bulksubmitglfiledata.length > 0" class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn v-if="bulksubmitglfiledata.length > 0 && !bulksubmitglfileerrorflag"
            @click="updateThreshold()" color="primary" small class="ml-2 shadow-off">Proceed to Upload</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="addeditrudialog" :heading="isViewMode? 'View Materiality':'Update Materiality'" :width="singleupdatetype || multipleMaterialityToggle?'':'600'"
        :loading="addeditruloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1 mb-2 font-weight-bold" v-if="!bulksubmit">{{addeditrudata.code}} -
              {{addeditrudata.name}}</div>
            <div v-else>
              <div class="d-flex align-center pb-4">
                <span class="display-1">{{(addeditrudata._id || []).length}}</span>
                <span class="ml-2 line-1-2">Reporting units<br />Selected</span>
              </div>
            </div>
            <div class="d-flex align-center mb-2 justify-center">
              <div class="">
                <v-chip small label color="commentaryMaterialityActual" v-if="cycle==='Actual'">{{cycle}} Cycle</v-chip>
                <v-chip small label color="commentaryMaterialityForecast" v-else-if="cycle==='Forecast'">{{cycle}}
                  Cycle</v-chip>
              </div>
              <v-spacer />
              <div class="d-flex align-center justify-center">
                <span
                  :class="`mr-3 ${singleupdatetype ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Overall</span>
                <v-switch inset dense class="mt-0" color="default" v-model="singleupdatetype" label="" hide-details
                  id="materiality-switch">
                </v-switch>
                <span :class="`ml-1 ${!singleupdatetype ? 'grey--text text--lighten-1' : ''} font-weight-bold`">GL
                  wise</span>
              </div>

            </div>
            <div class="mb-3" v-if="!bulksubmit">
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Network</span>
                <v-chip small label class="ml-2">{{addeditrudata.network}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Region</span>
                <v-chip small label class="ml-2">{{addeditrudata.region}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Country</span>
                <v-chip small label class="ml-2">{{addeditrudata.country}}</v-chip>
              </v-chip>
            </div>
            <div class="d-flex align-center mb-1">
              <lb-dropdown v-if = "isViewMode" hidedetails label="Cycle Type" :items="['Actual', 'Forecast']" v-model="cycle"
                   class='mr-2' width='15'></lb-dropdown>
              <lb-date v-if="isViewMode" type="month" hidedetails v-model="addeditrudata.period" label="Period"
                id="period" :min='minDate' :max="maxDate" class="mr-5"/>
              <lb-selectmultiplemonths v-else type="month" hidedetails v-model="addeditrudata.periods" label="Applicable Periods"
                id="applicable-periods " class='ml-1' :min='minDate' :max="maxDate"  :value="addeditrudata?.periods || []" :key="new Date().getTime()"/>

              <div class="d-flex align-center justify-center ml-4" v-if = "!isViewMode && !singleupdatetype">
                <span
                  :class="`mr-3 ${multipleMaterialityToggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Single Materiality</span>
                <v-switch inset dense class="mt-0" color="default" v-model="multipleMaterialityToggle" label="" hide-details
                  id="materiality-switch">
                </v-switch>
                <span :class="`ml-1 ${!multipleMaterialityToggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Multiple Materiality</span>
              </div>
              <v-btn v-if = "isViewMode"
                small
                color="primary"
                @click="getRUData()"
              >Get</v-btn> 

              <!-- <template v-if="singleupdatetype">
                <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0 ml-4" dense v-model="addeditrudata.control_thresholds_enable" :value="addeditrudata.control_thresholds_enable || false" label="Enable Local Control" :error="addeditruerror.control_thresholds_enable"/>
                <div class="line-1-2 ml-4" v-else>
                  <div>
                    <v-icon class="mr-1" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                    <v-icon class="mr-1">mdi-close</v-icon>
                    <span>Local Control</span>
                  </div>
                </div>
                <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0 ml-4" dense v-model="addeditrudata.management_thresholds_enable" :value="addeditrudata.management_thresholds_enable || false" label="Enable Local Management" :error="addeditruerror.management_thresholds_enable"/>
                <div class="line-1-2 ml-4" v-else>
                  <div>
                    <v-icon class="mr-1" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                    <v-icon class="mr-1">mdi-close</v-icon>
                    <span>Local Management</span>
                  </div>
                </div>
                <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0 ml-4" dense v-model="addeditrudata.control_thresholds_regional_enable" :value="addeditrudata.control_thresholds_regional_enable || false" label="Enable Regional Control" :error="addeditruerror.control_thresholds_regional_enable"/>
                <div class="line-1-2 ml-4" v-else>
                  <div>
                    <v-icon class="mr-1" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                    <v-icon class="mr-1">mdi-close</v-icon>
                    <span>Local Regional Management</span>
                  </div>
                </div>
                <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0 ml-4" dense v-model="addeditrudata.management_thresholds_regional_enable" :value="addeditrudata.management_thresholds_regional_enable || false" label="Enable Regional Management" :error="addeditruerror.management_thresholds_regional_enable"/>
                <div class="line-1-2 ml-4" v-else>
                  <div>
                    <v-icon class="mr-1" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                    <v-icon class="mr-1">mdi-close</v-icon>
                    <span>Local Regional Management</span>
                  </div>
                </div>
                <v-tooltip bottom content-class="tooltip-bottom" v-if="!ruleveledit.enable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" class="ml-2" color="" @click="ruleveledit.enable = true">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Enable/Disable</span>
                </v-tooltip>
              </template> -->
            </div>
            <div v-if="(addeditrudata?.periods || []).length" class="selected-months mb-3">
                <h3>Selected Months:</h3>
                <ul>
                <li v-for="(month, index) in addeditrudata?.periods" :key="index" class=' month-item'>
                    {{ month }}
                      <!-- <v-chip small label class="ml-2">{{ month}}</v-chip> -->
                    <v-btn small icon elevation="plain" @click="removeMonth(month)">
                    <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </li>
                </ul>
            </div>
            <div class="mb-1" id='materiality-note'>Note: <br>1.&nbsp; All numbers reported in the Commentary
              Application are in USD thousands (For instance, entering an amount/percentage of "30" corresponds to USD
              30,000).
              <br> 2.&nbsp; The Local and Regional Control percantages are set to 999999% by default and cannot be
              edited.
              <br>
              <div v-if='isViewMode'>3.&nbsp; Materiality can be viewed for the past 24 months and till the current financial year.</div>
              <div v-else>3.&nbsp; 		Materiality can be updated only for the current financial year.
                <br> 4.&nbsp;To update materiality at GL level for multiple periods , please use the bulk file upload option.
              </div>
 
            </div>
            <div v-if="isMaterialitySet!==true">
              <span
                  :class="`mr-3 font-weight-bold error--text`">Materiality is not yet set for this reporting unit</span>
            </div>
            <div v-if="multipleMaterialityToggle && !singleupdatetype">
                <v-simple-table class="v-table-color" fixed-header height='600'>
                  <thead>
                    <tr>
                      <th>Period</th>
                      <th></th>
                      <th>LC Amt</th>
                      <th>LC %</th>
                      <th>LM Amt</th>
                      <th>LM %</th>
                      <th>RC Amt</th>
                      <th>RC %</th>
                      <th>RM Amt</th>
                      <th>RM %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(periodData, periodKey) in formattedRUData" :key="periodKey" class="mb-1">
                      <td class='ellipsis-on'>{{ periodKey }}</td>
                      <!-- <td v-for="(value, key) in periodData" :key="key">
                        {{ key }}: {{ value }}
                      </td> -->
                      <td>
                        <div class='ellipsis-on'>
                          vs Proforma
                        </div>
                        <div class='ellipsis-on'>
                          vs Forecast
                        </div>
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_amount_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_amount_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_amount_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_amount_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_amount_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_amount_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_percent_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_percent_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_percent_proforma" id="l_c_a_pro" 
                        :disabled='true'/>
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_percent_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_percent_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_percent_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_amount_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_amount_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_amount_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_amount_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_amount_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_amount_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_percent_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_percent_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_percent_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_percent_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_percent_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_percent_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_amount_regional_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_amount_regional_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_amount_regional_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_amount_regional_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_amount_regional_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_amount_regional_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_percent_regional_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_percent_regional_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_percent_regional_proforma" id="l_c_a_pro" 
                        :disabled='true' />
                        <lb-number
                        v-model="formattedRUData[periodKey].control_thresholds_percent_regional_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].control_thresholds_percent_regional_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].control_thresholds_percent_regional_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_amount_regional_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_amount_regional_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_amount_regional_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_amount_regional_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_amount_regional_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_amount_regional_forecast" id="l_c_a_fore" />
                      </td>
                      <td>
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_percent_regional_proforma" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_percent_regional_proforma || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_percent_regional_proforma" id="l_c_a_pro" />
                        <lb-number
                        v-model="formattedRUData[periodKey].management_thresholds_percent_regional_forecast" min="0" hidedetails
                        :value="formattedRUData[periodKey].management_thresholds_percent_regional_forecast || '0'" label=""
                        :error="formattedRUErrorData[periodKey].management_thresholds_percent_regional_forecast" id="l_c_a_fore" />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
            </div>
            <div v-else>
              <div v-if="!singleupdatetype">
                <!-- <div class="border-on radius-on mb-4 pb-3">
                  <div class="d-flex align-center my-2 mx-4 body-2 font-weight-bold">
                    <div class="py-1">Enable/Disable</div>
                    <v-spacer/>
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="!ruleveledit.enable">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" color="" @click="ruleveledit.enable = true">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Enable/Disable</span>
                    </v-tooltip>
                  </div>
                  <v-divider />
                  <div class="mx-4 pt-6 pb-2">
                    <v-row>
                      <v-col cols="6" class="my-0 py-1">
                        <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0" dense v-model="addeditrudata.control_thresholds_enable" :value="addeditrudata.control_thresholds_enable || false" label="Enable Local Control" :error="addeditruerror.control_thresholds_enable"/>
                        <div class="line-1-2" v-else>
                          <div>
                            <v-icon class="mr-2" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                            <v-icon class="mr-2">mdi-close</v-icon>
                            <span>Local Control</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0" dense v-model="addeditrudata.management_thresholds_enable" :value="addeditrudata.management_thresholds_enable || false" label="Enable Local Management" :error="addeditruerror.management_thresholds_enable"/>
                        <div class="line-1-2" v-else>
                          <div>
                            <v-icon class="mr-2" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                            <v-icon class="mr-2">mdi-close</v-icon>
                            <span>Local Management</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0" dense v-model="addeditrudata.control_thresholds_regional_enable" :value="addeditrudata.control_thresholds_regional_enable || false" label="Enable Regional Control" :error="addeditruerror.control_thresholds_regional_enable"/>
                        <div class="line-1-2" v-else>
                          <div>
                            <v-icon class="mr-2" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                            <v-icon class="mr-2">mdi-close</v-icon>
                            <span>Local Regional Management</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <v-checkbox hide-details v-if="ruleveledit.enable" class="pa-0 ma-0" dense v-model="addeditrudata.management_thresholds_regional_enable" :value="addeditrudata.management_thresholds_regional_enable || false" label="Enable Regional Management" :error="addeditruerror.management_thresholds_regional_enable"/>
                        <div class="line-1-2" v-else>
                          <div>
                            <v-icon class="mr-2" v-if="addeditrudata.control_thresholds_enable">mdi-check</v-icon>
                            <v-icon class="mr-2">mdi-close</v-icon>
                            <span>Local Regional Management</span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div> -->
                <div class="border-on radius-on mb-4">
                  <div class="d-flex align-center my-2 mx-4 body-2 font-weight-bold">
                    <div class="py-1">Local Materiality Amount & Percentage</div>
                    <v-spacer />
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="!ruleveledit.local && !isViewMode">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" color="" @click="ruleveledit.local = true"
                          id='edit-local-materiality'>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit local values</span>
                    </v-tooltip>
                  </div>
                  <v-divider />
                  <div class="mx-4 pt-6 pb-2">
                    <v-row>
                      <v-col cols="6" class="my-0 py-1">
                        <div class="py-1 font-weight-bold">vs Proforma</div>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <div class="py-1 font-weight-bold">vs Forecast</div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5" class="my-0 mx-1 py-1">
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local" v-model="addeditrudata.control_thresholds_amount_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.control_thresholds_amount_proforma || '0'"
                            label="Control Thresholds Amount*" :error="addeditruerror.control_thresholds_amount_proforma"
                            id="l_c_a_pro" />
                          <div class="line-1-2 mb-0 ellipsis-on" id="l_c_a_pro" v-else>
                            <div>Control Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_amount_proforma === 0.001  && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if="addeditrudata.control_thresholds_amount_proforma !== 0.001">{{
                              addeditrudata.control_thresholds_amount_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_amount_proforma === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.local"
                            v-model="addeditrudata.control_thresholds_percent_proforma" prependiconinner="mdi-percent"
                            min="0" :value="addeditrudata.control_thresholds_percent_proforma"
                            label="Control Thresholds Percentage*"
                            :error="addeditruerror.control_thresholds_percent_proforma" id="l_c_p_pro" 
                            :disabled="true"/>
                          <div class="line-1-2 mb-4 ellipsis-on" id="l_c_p_pro" v-else>
                            <div>Control Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_percent_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if="addeditrudata.control_thresholds_percent_proforma !== 0.001">{{
                              addeditrudata.control_thresholds_percent_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_percent_proforma === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.floor_control_thresholds_amount_proforma" prependiconinner="mdi-cash"
                            min="0" :value="addeditrudata.floor_control_thresholds_amount_proforma"
                            label="Floor Control Thresholds*"
                            :error="addeditruerror.floor_control_thresholds_amount_proforma" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Control Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_control_thresholds_amount_proforma) }}
                            </div>
                          </div>
                        </v-row> -->
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.management_thresholds_amount_proforma" prependiconinner="mdi-cash"
                            min="0" :value="addeditrudata.management_thresholds_amount_proforma"
                            label="Management Thresholds Amount*"
                            :error="addeditruerror.management_thresholds_amount_proforma" id="l_m_a_pro" />
                          <div class="line-1-2 ellipsis-on" id="l_m_a_pro" v-else>
                            <div>Management Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_amount_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>
                            <span v-if="addeditrudata.management_thresholds_amount_proforma !== 0.001">{{addeditrudata.management_thresholds_amount_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_amount_proforma === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.management_thresholds_percent_proforma" prependiconinner="mdi-percent"
                            min="0" :value="addeditrudata.management_thresholds_percent_proforma || '0'"
                            label="Management Thresholds Percentage*"
                            :error="addeditruerror.management_thresholds_percent_proforma" id="l_m_p_pro" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="l_m_p_pro" v-else>
                            <div>Management Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_percent_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon> <span v-if='addeditrudata.management_thresholds_percent_proforma !== 0.001'>{{
                              addeditrudata.management_thresholds_percent_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_percent_proforma === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local" v-model="addeditrudata.floor_management_thresholds_amount_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_management_thresholds_amount_proforma "
                            label="Floor Management Thresholds*"
                            :error="addeditruerror.floor_management_thresholds_amount_proforma" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Management Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_management_thresholds_amount_proforma) }}
                            </div>
                          </div>
                        </v-row> -->
                      </v-col>
                      <v-col cols="5" class="my-0 py-1 mx-10">
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.local"
                            v-model="addeditrudata.control_thresholds_amount_forecast" prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.control_thresholds_amount_forecast" label="Control Thresholds Amount*"
                            :error="addeditruerror.control_thresholds_amount_forecast" />
                          <div class="line-1-2 ellipsis-on" v-else>
                            <div>Control Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_amount_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if='addeditrudata.control_thresholds_amount_forecast !== 0.001'>{{
                              addeditrudata.control_thresholds_amount_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_amount_forecast === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.local "
                            v-model="addeditrudata.control_thresholds_percent_forecast" prependiconinner="mdi-percent"
                            min="0" :value="addeditrudata.control_thresholds_percent_forecast || '0'"
                            label="Control Thresholds Percentage*"
                            :error="addeditruerror.control_thresholds_percent_forecast" id="l_c_a_fore" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="l_c_a_fore" v-else>
                            <div>Control Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_percent_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if='addeditrudata.control_thresholds_percent_forecast !== 0.001'>{{
                              addeditrudata.control_thresholds_percent_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_percent_forecast === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.floor_control_thresholds_amount_forecast" prependiconinner="mdi-cash"
                            min="0" :value="addeditrudata.floor_control_thresholds_amount_forecast"
                            label="Floor Control Thresholds*"
                            :error="addeditruerror.floor_control_thresholds_amount_forecast" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Control Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_control_thresholds_amount_forecast) }}
                            </div>
                          </div>
                        </v-row> -->
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.management_thresholds_amount_forecast" prependiconinner="mdi-cash"
                            min="0" :value="addeditrudata.management_thresholds_amount_forecast || '0'"
                            label="Management Thresholds Amount*"
                            :error="addeditruerror.management_thresholds_amount_forecast" id="l_c_m_fore" />
                          <div class="line-1-2 ellipsis-on" id="l_c_m_fore" v-else>
                            <div>Management Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_amount_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if='addeditrudata.management_thresholds_amount_forecast !== 0.001'>{{
                              addeditrudata.management_thresholds_amount_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_amount_forecast === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.management_thresholds_percent_forecast" prependiconinner="mdi-percent"
                            min="0" :value="addeditrudata.management_thresholds_percent_forecast || '0'"
                            label="Management Thresholds Percentage*"
                            :error="addeditruerror.management_thresholds_percent_forecast" id="l_m_a_fore" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="l_m_p_fore" v-else>
                            <div>Management Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_percent_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if='addeditrudata.management_thresholds_percent_forecast !== 0.001'>{{
                              addeditrudata.management_thresholds_percent_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_percent_forecast === 0.001 && ruleveledit.local && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.local"
                            v-model="addeditrudata.floor_management_thresholds_amount_forecast"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_management_thresholds_amount_forecast"
                            label="Floor Management Thresholds*"
                            :error="addeditruerror.floor_management_thresholds_amount_forecast" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Management Thresholds</div>
                            <div><v-icon
                                class="mr-2">mdi-cash</v-icon>{{$nova.formatNumber(addeditrudata.floor_management_thresholds_amount_forecast)
                              }}
                            </div>
                          </div>
                        </v-row> -->
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="border-on radius-on">
                  <div class="d-flex align-center my-2 mx-4 body-2 font-weight-bold">
                    <div class="py-1">Regional Materiality Amount & Percentage</div>
                    <v-spacer />
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="!ruleveledit.regional && !isViewMode">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" color="" @click="ruleveledit.regional = true"
                          id="edit-regional-materiality">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit regional values</span>
                    </v-tooltip>
                  </div>
                  <v-divider />
                  <div class="mx-4 pt-6 pb-2">
                    <v-row>
                      <v-col cols="6" class="my-0 py-1">
                        <div class="py-1 font-weight-bold">vs Proforma</div>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <div class="py-1 font-weight-bold">vs Forecast</div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5" class="my-0 mx-1 py-1">
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.control_thresholds_amount_regional_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.control_thresholds_amount_regional_proforma || '0'"
                            label="Control Thresholds Amount*"
                            :error="addeditruerror.control_thresholds_amount_regional_proforma" id="r_c_a_pro" />
                          <div class="line-1-2 ellipsis-on" id="r_c_a_pro" v-else>
                            <div>Control Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_amount_regional_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if='addeditrudata.control_thresholds_amount_regional_proforma !== 0.001'>{{
                              addeditrudata.control_thresholds_amount_regional_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_amount_regional_proforma === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.regional"
                            v-model="addeditrudata.control_thresholds_percent_regional_proforma"
                            prependiconinner="mdi-percent" min="0"
                            :value="addeditrudata.control_thresholds_percent_regional_proforma || '0'"
                            label="Control Thresholds Percentage*"
                            :error="addeditruerror.control_thresholds_percent_regional_proforma" id="r_c_p_pro" 
                            :disabled="true" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="r_c_p_pro" v-else>
                            <div>Control Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_percent_regional_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if='addeditrudata.control_thresholds_percent_regional_proforma !== 0.001'>{{
                              addeditrudata.control_thresholds_percent_regional_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_percent_regional_proforma === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.floor_control_thresholds_amount_regional_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_control_thresholds_amount_regional_proforma"
                            label="Floor Control Thresholds*"
                            :error="addeditruerror.floor_control_thresholds_amount_regional_proforma" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Control Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_control_thresholds_amount_regional_proforma) }}
                            </div>
                          </div>
                        </v-row> -->
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.management_thresholds_amount_regional_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.management_thresholds_amount_regional_proforma || '0'"
                            label="Management Thresholds Amount*"
                            :error="addeditruerror.management_thresholds_amount_regional_proforma" id="r_m_a_pro" />
                          <div class="line-1-2 ellipsis-on" id="r_m_a_pro" v-else>
                            <div>Management Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_amount_regional_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if='addeditrudata.management_thresholds_amount_regional_proforma !== 0.001'>{{
                              addeditrudata.management_thresholds_amount_regional_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_amount_regional_proforma === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.management_thresholds_percent_regional_proforma"
                            prependiconinner="mdi-percent" min="0"
                            :value="addeditrudata.management_thresholds_percent_regional_proforma || '0'"
                            label="Management Thresholds Percentage*"
                            :error="addeditruerror.management_thresholds_percent_regional_proforma" id="r_m_p_pro" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="r_m_p_pro" v-else>
                            <div>Management Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_percent_regional_proforma === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if='addeditrudata.management_thresholds_percent_regional_proforma !== 0.001'>{{
                              addeditrudata.management_thresholds_percent_regional_proforma }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_percent_regional_proforma === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.floor_management_thresholds_amount_regional_proforma"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_management_thresholds_amount_regional_proforma || '0'"
                            label="Floor Management Thresholds*"
                            :error="addeditruerror.floor_management_thresholds_amount_regional_proforma" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Management Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_management_thresholds_amount_regional_proforma) }}
                            </div>
                          </div>
                        </v-row> -->
                      </v-col>
                      <v-col cols="5" class="my-0 py-1 mx-10">
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.regional "
                            v-model="addeditrudata.control_thresholds_amount_regional_forecast"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.control_thresholds_amount_regional_forecast || '0'"
                            label="Control Thresholds Amount*"
                            :error="addeditruerror.control_thresholds_amount_regional_forecast" id="r_c_a_fore" />
                          <div class="line-1-2 ellipsis-on" id="r_c_a_fore" v-else>
                            <div>Control Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_amount_regional_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon><span v-if='addeditrudata.control_thresholds_amount_regional_forecast !== 0.001'>{{
                              addeditrudata.control_thresholds_amount_regional_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_amount_regional_forecast === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number
                            v-if="ruleveledit.regional "
                            v-model="addeditrudata.control_thresholds_percent_regional_forecast"
                            prependiconinner="mdi-percent" min="0"
                            :value="addeditrudata.control_thresholds_percent_regional_forecast || '0'"
                            label="Control Thresholds Percentage*"
                            :error="addeditruerror.control_thresholds_percent_regional_forecast" id="r_c_r_fore" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="r_c_p_fore" v-else>
                            <div>Control Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.control_thresholds_percent_regional_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon><span v-if='addeditrudata.control_thresholds_percent_regional_forecast !== 0.001'>{{
                              addeditrudata.control_thresholds_percent_regional_forecast }}</span>
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.control_thresholds_percent_regional_forecast === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.floor_control_thresholds_amount_regional_forecast"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_control_thresholds_amount_regional_forecast || '0'"
                            label="Floor Control Thresholds*"
                            :error="addeditruerror.floor_control_thresholds_amount_regional_forecast" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Control Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
    $nova.formatNumber(addeditrudata.floor_control_thresholds_amount_regional_forecast) }}
                            </div>
                          </div>
                        </v-row> -->
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.management_thresholds_amount_regional_forecast"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.management_thresholds_amount_regional_forecast || '0'"
                            label="Management Thresholds Amount*"
                            :error="addeditruerror.management_thresholds_amount_regional_forecast" id="r_m_a_fore" />
                          <div class="line-1-2 ellipsis-on" id="r_m_a_fore" v-else>
                            <div>Management Thresholds Amount
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_amount_regional_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              addeditrudata.management_thresholds_amount_regional_forecast }}
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_amount_regional_forecast === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.management_thresholds_percent_regional_forecast"
                            prependiconinner="mdi-percent" min="0"
                            :value="addeditrudata.management_thresholds_percent_regional_forecast || '0'"
                            label="Management Thresholds Percentage*"
                            :error="addeditruerror.management_thresholds_percent_regional_forecast" id="r_m_p_fore" />
                          <div class="line-1-2 mb-4 ellipsis-on" id="r_m_p_fore" v-else>
                            <div>Management Thresholds Percentage
                              <v-tooltip bottom content-class="tooltip-bottom" v-if="addeditrudata.management_thresholds_percent_regional_forecast === 0.001 && isViewMode">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{infoMessage}}</span>
                              </v-tooltip>
                            </div>
                            <div><v-icon class="mr-2">mdi-percent</v-icon>{{
                              addeditrudata.management_thresholds_percent_regional_forecast }}
                            </div>
                          </div>
                          <div class="line-1-2 mb-4" id="l_c_p_pro" v-if="addeditrudata.management_thresholds_percent_regional_forecast === 0.001 && ruleveledit.regional && isViewMode">
                            <v-tooltip bottom content-class="tooltip-bottom">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              <span>{{infoMessage}}</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                        <!-- <v-row class="my-0 py-1">
                          <lb-number v-if="ruleveledit.regional"
                            v-model="addeditrudata.floor_management_thresholds_amount_regional_forecast"
                            prependiconinner="mdi-cash" min="0"
                            :value="addeditrudata.floor_management_thresholds_amount_regional_forecast || '0'"
                            label="Floor Management Thresholds*"
                            :error="addeditruerror.floor_management_thresholds_amount_regional_forecast" />
                          <div class="line-1-2 mb-4" v-else>
                            <div>Floor Management Thresholds</div>
                            <div><v-icon class="mr-2">mdi-cash</v-icon>{{
                              $nova.formatNumber(addeditrudata.floor_management_thresholds_amount_regional_forecast) }}
                            </div>
                          </div>
                        </v-row> -->
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
              <div v-else>
                <lb-datatablenew :headers="glheaders" :items="formattedGLData"
                  :enableslot="['code', 'name', 'edit_buttons','datatypes', 'control_thresholds_amount', 'control_thresholds_percent', 'floor_control_thresholds', 'management_thresholds_amount', 'management_thresholds_percent', 'floor_management_thresholds', 'control_thresholds_amount_regional', 'control_thresholds_percent_regional', 'floor_control_thresholds_regional', 'management_thresholds_amount_regional', 'management_thresholds_percent_regional', 'floor_management_thresholds_regional']"
                  :loading="loading">
                  <template v-slot:code="{ item }">
                    <div class="d-flex align-center">
                      <v-divider vertical class="ml-4 mr-0" v-if="item.__child" />
                      <div>{{item.code}}</div>
                    </div>
                  </template>
                  <template v-slot:name="{ item }">
                    <div class="d-flex align-center">
                      <div class='mr-0'>{{item.name}}</div>
                      <!-- <v-spacer /> -->
                      <!-- <v-tooltip bottom content-class="tooltip-bottom" v-if="editablegls.indexOf(item._id) === -1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" v-on="on" color="" @click="editablegls.push(item._id)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit this GL</span>
                      </v-tooltip> -->
                    </div>
                  </template>
                  <template v-slot:datatypes="">
                    <div class='ellipsis-on'>
                      vs Proforma
                    </div>
                    <div class='ellipsis-on'>
                      vs Forecast
                    </div>
                  </template>
                  <template v-slot:edit_buttons="{item}">
                    <v-col>
                      <v-row>
                        <v-tooltip bottom content-class="tooltip-bottom"
                          v-if="editablegls.proforma.indexOf(item._id) === -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" color=""
                              @click="editablegls.proforma.push(item._id)" id="edit-proforma-gl">
                              <v-icon color='primaryicon'>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Proforma GL </span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" color=""
                              @click="cancelEditItem(item._id,'proforma')" id="cancel-proforma-gl">
                              <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancel</span>
                        </v-tooltip>
                      </v-row>
                      <v-row>
                        <v-tooltip bottom content-class="tooltip-bottom"
                          v-if="editablegls.forecast.indexOf(item._id) === -1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" color=""
                              @click="editablegls.forecast.push(item._id)" id="edit-forecast-gl">
                              <v-icon color="secondaryicon">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Forecast GL</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" color=""
                              @click="cancelEditItem(item._id,'forecast')" id="cancel-forecast-gl">
                              <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancel</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </template>
                  <template v-slot:control_thresholds_amount="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_amount_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_amount_proforma || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_amount_proforma" id="l_c_a_pro" />
                      <div class="text-right" id="l_c_a_pro" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_amount_proforma !== 0.001">{{addeditgldata[item._id].control_thresholds_amount_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_amount_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_amount_forecast || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_amount_forecast" id="l_c_a_fore" />
                      <div class="text-right" id="l_c_a_fore" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_amount_forecast !== 0.001">{{addeditgldata[item._id].control_thresholds_amount_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:control_thresholds_percent="{ item }">
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_percent_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_percent_proforma || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_percent_proforma" id="l_c_p_pro" 
                        :disabled="true"/>
                      <div class="text-right" id="l_c_p_pro" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_percent_proforma !== 0.001">{{addeditgldata[item._id].control_thresholds_percent_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_percent_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_percent_forecast || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_percent_forecast" id="l_c_p_fore" />
                      <div class="text-right" id="l_c_p_fore" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_percent_forecast !== 0.001">{{addeditgldata[item._id].control_thresholds_percent_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-slot:floor_control_thresholds="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_control_thresholds_amount_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].floor_control_thresholds_amount_proforma || '0'" label=""
                        :error="addeditglerror[item._id].floor_control_thresholds_amount_proforma" />
                      <div class="text-right" v-else>
                        {{ $nova.formatNumber(addeditgldata[item._id].floor_control_thresholds_amount_proforma) || 0}}
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_control_thresholds_amount_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].floor_control_thresholds_amount_forecast || '0'" label=""
                        :error="addeditglerror[item._id].floor_control_thresholds_amount_forecast" />
                      <div class="text-right" v-else>
                        {{ $nova.formatNumber(addeditgldata[item._id].floor_control_thresholds_amount_forecast) || 0}}
                      </div>
                    </div>
                  </template> -->
                  <template v-slot:management_thresholds_amount="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_amount_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].management_thresholds_amount_proforma || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_amount_proforma" id="l_m_a_pro" />
                      <div class="text-right" id="l_m_a_pro" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_amount_proforma !== 0.001">{{addeditgldata[item._id].management_thresholds_amount_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_amount_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].management_thresholds_amount_forecast || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_amount_forecast" id="l_m_a_fore" />
                      <div class="text-right" id="l_m_a_fore" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_amount_forecast !== 0.001">{{addeditgldata[item._id].management_thresholds_amount_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:management_thresholds_percent="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_percent_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].management_thresholds_percent_proforma || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_percent_proforma" id="l_m_p_pro" />
                      <div class="text-right" v-else><span v-if="addeditgldata[item._id].management_thresholds_percent_proforma !== 0.001">{{addeditgldata[item._id].management_thresholds_percent_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_percent_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].management_thresholds_percent_forecast || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_percent_forecast" id="l_m_p_fore" />
                      <div class="text-right" v-else><span v-if="addeditgldata[item._id].management_thresholds_percent_forecast !== 0.001">{{addeditgldata[item._id].management_thresholds_percent_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-slot:floor_management_thresholds="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_management_thresholds_amount_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].floor_management_thresholds_amount_proforma || '0'" label=""
                        :error="addeditglerror[item._id].floor_management_thresholds_amount_proforma" />
                      <div class="text-right" v-else>{{ addeditgldata[item._id].floor_management_thresholds_amount_proforma
                        ||
                        0 }}
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_management_thresholds_amount_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].floor_management_thresholds_amount_forecast || '0'" label=""
                        :error="addeditglerror[item._id].floor_management_thresholds_amount_forecast" />
                      <div class="text-right" v-else>{{ addeditgldata[item._id].floor_management_thresholds_amount_forecast
                        ||
                        0 }}
                      </div>
                    </div>
                  </template> -->
                  <template v-slot:control_thresholds_amount_regional="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_amount_regional_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_amount_regional_proforma || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_amount_regional_proforma" id="r_c_a_pro" />
                      <div class="text-right" id="r_c_a_pro" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_amount_regional_proforma !== 0.001">{{addeditgldata[item._id].control_thresholds_amount_regional_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_amount_regional_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_amount_regional_forecast || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_amount_regional_forecast" id="r_c_a_fore" />
                      <div class="text-right" id="r_c_a_fore" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_amount_regional_forecast !== 0.001">{{addeditgldata[item._id].control_thresholds_amount_regional_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:control_thresholds_percent_regional="{ item }">
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.proforma.indexOf(item._id) > -1 "
                        v-model="addeditgldata[item._id].control_thresholds_percent_regional_proforma" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_percent_regional_proforma || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_percent_regional_proforma" id="r_c_p_pro" 
                        :disabled="true"/>
                      <div class="text-right" id="r_c_p_pro" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_percent_regional_proforma !== 0.001">{{addeditgldata[item._id].control_thresholds_percent_regional_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number
                        v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].control_thresholds_percent_regional_forecast" min="0" hidedetails
                        :value="addeditgldata[item._id].control_thresholds_percent_regional_forecast || '0'" label=""
                        :error="addeditglerror[item._id].control_thresholds_percent_regional_forecast" id="r_c_p_fore" />
                      <div class="text-right" id="r_c_p_fore" v-else>
                        <span v-if="addeditgldata[item._id].control_thresholds_percent_regional_forecast !== 0.001">{{addeditgldata[item._id].control_thresholds_percent_regional_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-slot:floor_control_thresholds_regional="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_control_thresholds_amount_regional_proforma" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].floor_control_thresholds_amount_regional_proforma || '0'" label=""
                        :error="addeditglerror[item._id].floor_control_thresholds_amount_regional_proforma" />
                      <div class="text-right" v-else>
                        {{ $nova.formatNumber(addeditgldata[item._id].floor_control_thresholds_amount_regional_proforma)
                        || 0 }}
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_control_thresholds_amount_regional_forecast" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].floor_control_thresholds_amount_regional_forecast || '0'" label=""
                        :error="addeditglerror[item._id].floor_control_thresholds_amount_regional_forecast" />
                      <div class="text-right" v-else>
                        {{ $nova.formatNumber(addeditgldata[item._id].floor_control_thresholds_amount_regional_forecast)
                        || 0 }}
                      </div>
                    </div>
                  </template> -->
                  <template v-slot:management_thresholds_amount_regional="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_amount_regional_proforma" min="0"
                        hidedetails :value="addeditgldata[item._id].management_thresholds_amount_regional_proforma || '0'"
                        label="" :error="addeditglerror[item._id].management_thresholds_amount_regional_proforma"
                        id="r_m_a_pro" />
                      <div class="text-right" id="r_m_a_pro" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_amount_regional_proforma !== 0.001">{{addeditgldata[item._id].management_thresholds_amount_regional_proforma || 0}}</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_amount_regional_forecast" min="0"
                        hidedetails :value="addeditgldata[item._id].management_thresholds_amount_regional_forecast || '0'"
                        label="" :error="addeditglerror[item._id].management_thresholds_amount_regional_forecast"
                        id="r_m_a_fore" />
                      <div class="text-right" id="r_m_a_fore" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_amount_regional_forecast !== 0.001">{{addeditgldata[item._id].management_thresholds_amount_regional_forecast || 0}}</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <template v-slot:management_thresholds_percent_regional="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_percent_regional_proforma" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].management_thresholds_percent_regional_proforma || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_percent_regional_proforma"
                        id="r_m_p_pro" />
                      <div class="text-right" id="r_m_p_pro" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_percent_regional_proforma !== 0.001">{{addeditgldata[item._id].management_thresholds_percent_regional_proforma || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].management_thresholds_percent_regional_forecast" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].management_thresholds_percent_regional_forecast || '0'" label=""
                        :error="addeditglerror[item._id].management_thresholds_percent_regional_forecast"
                        id="r_m_p_fore" />
                      <div class="text-right" id="r_m_p_fore" v-else>
                        <span v-if="addeditgldata[item._id].management_thresholds_percent_regional_forecast !== 0.001">{{addeditgldata[item._id].management_thresholds_percent_regional_forecast || 0}}%</span>
                        <span v-else> - </span>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-slot:floor_management_thresholds_regional="{ item }">
                    <div class="py-1">
                      <lb-number v-if="editablegls.proforma.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_management_thresholds_amount_regional_proforma" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].floor_management_thresholds_amount_regional_proforma || '0'"
                        label="" :error="addeditglerror[item._id].floor_management_thresholds_amount_regional_proforma" />
                      <div class="text-right" v-else>
                        {{addeditgldata[item._id].floor_management_thresholds_amount_regional_proforma || 0}}
                      </div>
                    </div>
                    <div class="py-1">
                      <lb-number v-if="editablegls.forecast.indexOf(item._id) > -1"
                        v-model="addeditgldata[item._id].floor_management_thresholds_amount_regional_forecast" min="0"
                        hidedetails
                        :value="addeditgldata[item._id].floor_management_thresholds_amount_regional_forecast || '0'"
                        label="" :error="addeditglerror[item._id].floor_management_thresholds_amount_regional_forecast" />
                      <div class="text-right" v-else>{{
                        addeditgldata[item._id].floor_management_thresholds_amount_regional_forecast || 0 }}
                      </div>
                    </div>
                  </template> -->
                </lb-datatablenew>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions v-if="!isViewMode">
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="primary" v-if="addeditrudata.periods && addeditrudata.periods.length>0" @click="updateThreshold()"
            id='updatethreshold'>Update</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="pendingdialog" heading="Pending approval list" width="900" :loading="pendingloading">
        <template v-slot:body>
          <div>
            <ApprovalsList :data="pendingrequestlist" @loading="setPendingLoading" :key="new Date().getTime()"/>
          </div>
        </template>
        <!-- <template v-slot:actions>
          <span class="caption">*Required</span>
          <v-spacer></v-spacer>
          <v-btn small color="primary" v-if="addeditrudata.fromperiod" @click="updateThreshold()">Update</v-btn>
        </template> -->
      </lb-dialog>

      <lb-dialog v-model="faqdialog" heading="Frequently asked question" width=800 :loading="loading">
        <template v-slot:body>
          <h2>Question:&nbsp; What is materiality? How can I update this?'</h2>
          <span><b>Answer:</b>&nbsp; Materiality involves establishing specific thresholds (values or percentages) at
            the
            local or regional level by Tool Admin Preparers. These thresholds help identify GL's that exceed the control
            or management
            threshold set based on the materiality criteria.The numbers in the application will be in USD and hence the
            thresholds are expected to be in USD.For a detailed explanation and step by step instruction, please refer
            the section in the user manual on 'Materiality'</span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; What should I do if I encounter issues with the Materiality feature, such as being unable
            to set it up or
            view specific GLs?</h2>
          <span>
            <b>Answer:</b>
            <br>
            1. Refresh the screen and try in 10 minutes
            <br>
            2. If issues persist, reach out to the Consark Support Team with the issue and screenshot if any
          </span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; Can I update threshold values for specific GL's or view the materiality set for the
            reporting
            unit?</h2>
          <span><b>Answer:</b>&nbsp; Yes, this can be viewed specifically for a GL or reporting unit. Click on the edit
            button
            under the action layout column and toggle to the screen to set and view materiality at a GL level.</span>
          <v-divider :thickness="2"></v-divider>
          <br>
          <h2>Question:&nbsp; Should I enter Materilaity in both the absolute numbers and Percentage or just choose one
            of
            them ?</h2>
          <span><b>Answer:</b>&nbsp; Starlight Application is designed to compare the materilaity set in absolute
            numbers and
            the percentage and then, apply the lower of the two in flagging off commentary requirements against every
            Account Code. </span>

        </template>
      </lb-dialog>
    </div>
  </div>
</template>

<script>
import ApprovalsList from "./IndividualApprovalsList.vue";
// import cfilter from './Filter'

export default {
  name: 'commentary_materiality',
  data: function(){
    return {
      right: "commentary_materiality",
      loading: false,
      data: [],
      searchtext: "",
      addeditrudialog: false,
      addeditruloading: false,
      addeditrudata: {},
      addeditruerror: {},
      addeditgldata: {},
      addeditglerror: {},
      updateuserdialog: false,
      addusertype: "",
      bulkchange: {},
      bulkchangeloading: false,
      bulkchangedialog: false,
      cycle:"",
      bulksubmit: false,
      filesubmit: false,
      singleupdatetype: false,
      gllist: {},
      glcodenamemap: {},
      glcodeidmap: {},
      formattedGLData: [],
      allgls: [],
      editablegls: {proforma:[],forecast:[]},
      ruleveledit: {local: false, regional: false, enable: false},
      formatedData: [],
      rucodenamemap: {},
      rucodeidmap: {},
      pendingrequestlist: [],
      pendingdialog: false,
      pendingloading: false,
      fileprocessing: {},
      bulksubmitrudialog: false,
      bulksubmitruloading: false,
      bulksubmitrufileerrorflag: false,
      bulksubmitrufile: null,
      bulksubmitrufiledata: [],
      bulksubmitrufileerror: [],
      bulksubmitrufilecolumns: ["rucode", "runame",'period', 'a_c_l_amt_pro', 'a_c_l_per_pro', 'a_m_l_amt_pro', 'a_m_l_per_pro','a_c_r_amt_pro', 'a_c_r_per_pro', 'a_m_r_amt_pro', 'a_m_r_per_pro','a_c_l_amt_fore', 'a_c_l_per_fore', 'a_m_l_amt_fore', 'a_m_l_per_fore',
                                'a_c_r_amt_fore', 'a_c_r_per_fore', 'a_m_r_amt_fore', 'a_m_r_per_fore', 'f_c_l_amt_pro', 'f_c_l_per_pro', 'f_m_l_amt_pro', 'f_m_l_per_pro', 'f_c_r_amt_pro', 'f_c_r_per_pro', 'f_m_r_amt_pro', 'f_m_r_per_pro',
                                'f_c_l_amt_fore', 'f_c_l_per_fore', 'f_m_l_amt_fore', 'f_m_l_per_fore','f_c_r_amt_fore', 'f_c_r_per_fore', 'f_m_r_amt_fore', 'f_m_r_per_fore'],

      bulksubmitgldialog: false,
      bulksubmitglloading: false,
      bulksubmitglfileerrorflag: false,
      bulksubmitglfile: null,
      bulksubmitglfiledata: [],
      bulksubmitglfileerror: [],
      bulksubmitglfilecolumns: ["rucode", "runame", "glcode", "glname", 'period','a_c_l_amt_pro', 'a_c_l_per_pro', 'a_m_l_amt_pro', 'a_m_l_per_pro','a_c_r_amt_pro', 'a_c_r_per_pro', 'a_m_r_amt_pro', 'a_m_r_per_pro','a_c_l_amt_fore', 'a_c_l_per_fore', 'a_m_l_amt_fore', 'a_m_l_per_fore',
                                'a_c_r_amt_fore', 'a_c_r_per_fore', 'a_m_r_amt_fore', 'a_m_r_per_fore', 'f_c_l_amt_pro', 'f_c_l_per_pro', 'f_m_l_amt_pro', 'f_m_l_per_pro', 'f_c_r_amt_pro', 'f_c_r_per_pro', 'f_m_r_amt_pro', 'f_m_r_per_pro','f_c_l_amt_fore', 'f_c_l_per_fore', 'f_m_l_amt_fore', 
                                'f_m_l_per_fore','f_c_r_amt_fore', 'f_c_r_per_fore', 'f_m_r_amt_fore', 'f_m_r_per_fore'],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "code",
          text: "RU Code",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: false,
          filterable: true,
          lock: true,
        },
        {
          value: "network",
          text: "Network",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "region",
          text: "Region",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "currency",
          text: "Currency",
          sortable: true,
          filterable: true,
          lock: false,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      glheaders: [
        {
          value: "code",
          text: "GL Code",
          sortable: false,
          filterable: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "datatypes",
          text: "",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "edit_buttons",
          text: "",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "control_thresholds_amount",
          text: "LC Amt",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        {
          value: "control_thresholds_percent",
          text: "LC %",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        // {
        //   value: "floor_control_thresholds",
        //   text: "L Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        //   lock: true,
        // },
        {
          value: "management_thresholds_amount",
          text: "LM Amt",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        {
          value: "management_thresholds_percent",
          text: "LM %",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        // {
        //   value: "floor_management_thresholds",
        //   text: "L Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        //   lock: true,
        // },
        {
          value: "control_thresholds_amount_regional",
          text: "RC Amt",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        {
          value: "control_thresholds_percent_regional",
          text: "RC %",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        // {
        //   value: "floor_control_thresholds_regional",
        //   text: "F Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        //   lock: true,
        // },
        {
          value: "management_thresholds_amount_regional",
          text: "RM Amt",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        {
          value: "management_thresholds_percent_regional",
          text: "RM %",
          alignment: "text-right",
          sortable: false,
          lock: true,
        },
        // {
        //   value: "floor_management_thresholds_regional",
        //   text: "R Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        //   lock: true,
        // },
      ],
      faqContent: [
        {
          'question':'What is materiality? How can I update this?',
          'answer': "Materiality involves establishing specific thresholds (values or percentages) at the local or regional level by Tool Admin Preparers. These thresholds help identify GL's that exceed the control or management threshold set based on the materiality criteria.The numbers in the application will be in USD and hence the thresholds are expected to be in USD.For a detailed explanation and step by step instruction, please refer the section in the user manual on 'Materiality'",
        },
        {
          'question': 'What should I do if I encounter issues with the Materiality feature, such as being unable to set it up or view specific GLs?',
          'answer': [' Refresh the screen and try in 10 minutes','If issues persist, reach out to the Consark Support Team with the issue and screenshot if any']
        }
      ],
      faqdialog:false,
      emptyRuCode:false,
      addedgldatacopy:{},
      isMaterialitySet: true,
      infoMessage:"",
      isViewMode:false,
      minDate:'',
      maxDate:'2025-4',
      selectedRUId:'',
      multipleMaterialityToggle:false,
      formattedRUData:{},
      formattedRUErrorData:{},
      fydetails:{'start':'01','end':'12'}
    }
  },
  components: {
    ApprovalsList
  },
  activated() {
    this.refreshData();
  },
  created() {
    this.refreshData();
    const today = new Date();
    if(today.getMonth() > this.fydetails.end){
      this.maxDate = (today.getFullYear()+1)+"-"+this.fydetails.end;
    }
    else if(today.getMonth() < this.fydetails.end){
      this.maxDate = (today.getFullYear())+"-"+this.fydetails.end;
    }
  },
  mounted() {
  },
  methods: {
    refreshData() {
      this.getData();
    },
    setPendingLoading(v) {
      this.pendingloading = v;
    },
    getData() {
      this.loading = true;
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/reportingunit/getalllist", {filter: {}}).then(dt => {
        if(dt.data.status === "success") {
          this.data = [];
          this.data = dt.data.data;
          this.formateData();
          return this.axios.post("/v2/varianceanalysis/gllist/get");
        }
        else throw new Error (dt.data.message || "Error reading data");
      }).then(dt => {
        if(dt.data.status === "success") {
          this.gllist = {};
          let allgllist = []
          this.glcodenamemap = {};
          this.glcodeidmap = {};
          this.gllist["primary"] = [];
          // this.addeditgldata = {};
          for (const i of (dt.data.data || [])) {
            this.glcodenamemap[i.code] = i.name;
            this.glcodeidmap[i.code] = i._id;
            // this.addeditgldata[item._id] = {};
            // this.addeditglerror[item.code] = {};
            allgllist.push(i.code);
            if(i.parent) {
              this.gllist[i.parent] = this.gllist[i.parent] || [];
              this.gllist[i.parent].push(i)
            }else {
              this.gllist["NA"] = this.gllist["NA"] || [];
              this.gllist["NA"].push(i)
            }
          }
          this.gllist["primary"].push({code: "NA", order: 99999, name: "Ungrouped: Other sub-schedules and further Analysis Accounts"})
          this.gllist["primary"] = this.reorder(this.gllist["primary"] || [], 'order');
          for (const i of this.gllist['primary']) {
            this.gllist[i.code] = this.reorder(this.gllist[i.code] || [], 'order');
          }
          this.allgls = allgllist;
          this.getPendingApprovalData();
        }
        else throw new Error (dt.data.message || "Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    getPendingApprovalData() {
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/materiality/getpendingrequest").then(dt => {
        if(dt.data.status === "success") {
          if(dt.data.data.length > 0) {
            this.pendingrequestlist = dt.data.data;
          }
        }
        else throw new Error ("Error reading pending request data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
      })
    },
    getRUData() {
      let rudata = [];
      this.addeditruloading = true;
      this.addeditrudialog = true;
      this.isMaterialitySet=true
      if(this.addeditrudata?.period !== undefined && this.addeditrudata?.period != "-undefined" && this.addeditrudata?.period != ""){
        const dateString = this.addeditrudata?.period || '';
        const [year, month] = dateString.split('-');
        this.addeditrudata.period = `${year}-${month}`;
      }else{
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        this.addeditrudata.period = `${year}-${month}`;
      }
      this.cycle =  this.cycle === "" || this.cycle === undefined || this.cycle === null? 'Actual' : this.cycle
      this.axios.post("/v2/varianceanalysis/materiality/getmy/"+this.selectedRUId, {filter: {cycletype: this.cycle, period:this.addeditrudata.period}}).then(dt => {
        if(dt.data.status === "success") {
          let ndt = {};
          rudata = dt.data
          for (const i of dt.data.data) {
            i._id = i.gl_code;
            ndt[i._id] = i;
          }
          if(rudata.data.length !== 0 ){
            this.isMaterialitySet=true
            //newly added keys
            const thresholdKeys = [
                'control_thresholds_amount_proforma', 'control_thresholds_percent_proforma',
                'management_thresholds_amount_proforma', 'management_thresholds_percent_proforma',
                'control_thresholds_amount_regional_proforma', 'control_thresholds_percent_regional_proforma',
                'management_thresholds_amount_regional_proforma', 'management_thresholds_percent_regional_proforma',
                'control_thresholds_amount_forecast', 'control_thresholds_percent_forecast',
                'management_thresholds_amount_forecast', 'management_thresholds_percent_forecast',
                'control_thresholds_amount_regional_forecast', 'control_thresholds_percent_regional_forecast',
                'management_thresholds_amount_regional_forecast', 'management_thresholds_percent_regional_forecast',
              ];
            thresholdKeys.forEach(key => {
              if(typeof rudata?.absolute_threshold_values?.[key] === 'undefined' || rudata?.absolute_threshold_values?.[key] === null){
                this.addeditrudata[key] = 0.001;
              }else{
                this.addeditrudata[key] = rudata?.absolute_threshold_values?.[key] || 0;
              }
            });
            this.addeditrudata['control_thresholds_percent_proforma'] = 999999
            this.addeditrudata['control_thresholds_percent_regional_proforma'] = 999999
            
            // this.addeditrudata.floor_control_thresholds_amount_proforma = rudata?.absolute_threshold_values?.floor_control_thresholds_amount_proforma || 0;
            // this.addeditrudata.floor_management_thresholds_amount_proforma = rudata?.absolute_threshold_values?.floor_management_thresholds_amount_proforma || 0;
            // this.addeditrudata.floor_control_thresholds_amount_regional_proforma = rudata?.absolute_threshold_values?.floor_control_thresholds_amount_regional_proforma || 0;
            // this.addeditrudata.floor_management_thresholds_amount_regional_proforma = rudata?.absolute_threshold_values?.floor_management_thresholds_amount_regional_proforma || 0;
            // this.addeditrudata.floor_control_thresholds_amount_forecast = rudata?.absolute_threshold_values?.floor_control_thresholds_amount_forecast || 0;
            // this.addeditrudata.floor_management_thresholds_amount_forecast = rudata?.absolute_threshold_values?.floor_management_thresholds_amount_forecast || 0;
            // this.addeditrudata.floor_control_thresholds_amount_regional_forecast = rudata?.absolute_threshold_values?.floor_control_thresholds_amount_regional_forecast || 0;
          // this.addeditrudata.floor_management_thresholds_amount_regional_forecast = rudata?.absolute_threshold_values?.floor_management_thresholds_amount_regional_forecast || 0;
          }else{
            this.isMaterialitySet=false
            // Proforma keys
            this.addeditrudata.control_thresholds_amount_proforma = 0;
            this.addeditrudata.control_thresholds_percent_proforma =0;
            this.addeditrudata.management_thresholds_amount_proforma = 0;
            this.addeditrudata.management_thresholds_percent_proforma = 0;
            this.addeditrudata.control_thresholds_amount_regional_proforma = 0;
            this.addeditrudata.control_thresholds_percent_regional_proforma = 0;
            this.addeditrudata.management_thresholds_amount_regional_proforma = 0;
            this.addeditrudata.management_thresholds_percent_regional_proforma = 0;

            // Forecast keys
            this.addeditrudata.control_thresholds_amount_forecast =  0;
            this.addeditrudata.control_thresholds_percent_forecast = 0;
            this.addeditrudata.management_thresholds_amount_forecast = 0;
            this.addeditrudata.management_thresholds_percent_forecast = 0;
            this.addeditrudata.control_thresholds_amount_regional_forecast = 0;
            this.addeditrudata.control_thresholds_percent_regional_forecast = 0;
            this.addeditrudata.management_thresholds_amount_regional_forecast = 0;
            this.addeditrudata.management_thresholds_percent_regional_forecast = 0;
          }
          this.formattedGLData = this.generateFormattedGLList(ndt,false);
        }
        else throw new Error ("Error reading materiality data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditruloading = false;
      })
    },
    formateData() {
      let ArrayTemp = [];
      this.rucodenamemap = {};
      this.rucodeidmap = {};
      this.data.forEach((i, k) => {
        this.rucodenamemap[i.code] = i.name?.trim();
        this.rucodeidmap[i.code] = i._id;
        ArrayTemp.push({
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          code: i.code,
          name: i.name,
          network: (i.network_detail || {}).name || "NA",
          region: (i.region_detail || {}).name || "NA",
          country: (i.country_detail || {}).name || "NA",
          currency: i.currency,
          created_at: this.toISOConverter(i.created_at)+" IST",
          updated_at: this.toISOConverter(i.updated_at)+" IST",
        });
      });
      this.formatedData = ArrayTemp;
    },
    editData(v, cycle, type, ru,isView=false) {
      this.cycle = cycle;
      this.selectedRUId = ru;
      this.editablegls = {proforma:[],forecast:[]};
      this.isMaterialitySet=true;
      this.formattedRUData = {}
      this.formattedRUErrorData = {};
      this.multipleMaterialityToggle = false
      const today = new Date();
      if(!isView){
        this.ruleveledit.local = true;
        this.ruleveledit.regional = true;
        this.ruleveledit.enable = true;

        if(today.getMonth() > this.fydetails.start){
          this.minDate = (today.getFullYear())+"-"+this.fydetails.start;
        }
        else if(today.getMonth() < this.fydetails.start){
          this.minDate = (today.getFullYear()-1)+"-"+this.fydetails.start;
        }
      }else{
        this.ruleveledit.local = false;
        this.ruleveledit.regional = false;
        this.ruleveledit.enable = false;
        today.setMonth(today.getMonth() - 24); // Subtract 24 months
        this.minDate = today.toISOString().slice(0, 7); 
      }
      this.isViewMode = isView
      
      if(type === "bulk") {
        this.infoMessage='Multiple materiality thresholds set at GL level'
        this.bulksubmit = true;
        this.filesubmit = false;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};
      }
      else if(type === "single") {
        this.infoMessage='Multiple materiality thresholds set at GL level'
        this.bulksubmit = false;
        this.filesubmit = false;
        this.addeditrudata=this.$nova.deepCopyJson(v);
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        if(this.addeditrudata.control_thresholds_percent) this.addeditrudata.control_thresholds_percent = this.addeditrudata.control_thresholds_percent*100;
        if(this.addeditrudata.management_thresholds_percent) this.addeditrudata.management_thresholds_percent = this.addeditrudata.management_thresholds_percent*100;
      }
      else if(type === "rufile" || type === "glfile") {
        this.bulksubmit = false;
        this.filesubmit = true;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.bulksubmitrufiledata = [];
        this.bulksubmitglfiledata = [];
        this.bulksubmitrufileerror = [];
        this.bulksubmitglfileerror = [];
        this.bulksubmitrufileerrorflag = false;
        this.bulksubmitglfileerrorflag = false;
        this.emptyRuCode=false
        if(type === "rufile") this.bulksubmitrudialog = true;
        else this.bulksubmitgldialog = true;
      }
      if(type === "bulk" || type === "single"){
        this.addeditrudata._id = ru;
        this.addeditrudata.control_thresholds_amount_proforma =0.001;
        this.addeditrudata.control_thresholds_percent_proforma = 999999;
        // this.addeditrudata.floor_control_thresholds_amount_proforma =  0;
        this.addeditrudata.management_thresholds_amount_proforma = 0.001;
        this.addeditrudata.management_thresholds_percent_proforma = 0.001;
        // this.addeditrudata.floor_management_thresholds_amount_proforma =  0;
        this.addeditrudata.control_thresholds_amount_regional_proforma =  0.001;
        this.addeditrudata.control_thresholds_percent_regional_proforma = 999999;
        // this.addeditrudata.floor_control_thresholds_amount_regional_proforma = 0;
        this.addeditrudata.management_thresholds_amount_regional_proforma = 0.001;
        this.addeditrudata.management_thresholds_percent_regional_proforma = 0.001;
        // this.addeditrudata.floor_management_thresholds_amount_regional_proforma = 0;

        this.addeditrudata.control_thresholds_amount_forecast = 0.001;
        this.addeditrudata.control_thresholds_percent_forecast = 0.001;
        // this.addeditrudata.floor_control_thresholds_amount_forecast = 0;
        this.addeditrudata.management_thresholds_amount_forecast = 0.001;
        this.addeditrudata.management_thresholds_percent_forecast = 0.001;
        // this.addeditrudata.floor_management_thresholds_amount_forecast = 0;
        this.addeditrudata.control_thresholds_amount_regional_forecast = 0.001;
        this.addeditrudata.control_thresholds_percent_regional_forecast = 0.001;
        // this.addeditrudata.floor_control_thresholds_amount_regional_forecast = 0;
        this.addeditrudata.management_thresholds_amount_regional_forecast = 0.001;
        this.addeditrudata.management_thresholds_percent_regional_forecast = 0.001;
        // this.addeditrudata.floor_management_thresholds_amount_regional_forecast = 0;
        this.addeditrudialog=true;
        if(ru instanceof Array || !this.isViewMode) this.formattedGLData = this.generateFormattedGLList({},true);
        else this.getRUData(ru);
        console.log(this.addeditrudialog)
      }
    },
    updateThreshold() {
      this.addeditruloading = true;
      let actualKeys =[
        'a_c_l_amt_pro', 'a_c_l_per_pro', 'a_m_l_amt_pro', 'a_m_l_per_pro',
        'a_c_r_amt_pro', 'a_c_r_per_pro', 'a_m_r_amt_pro', 'a_m_r_per_pro',
        'a_c_l_amt_fore', 'a_c_l_per_fore', 'a_m_l_amt_fore', 'a_m_l_per_fore',
        'a_c_r_amt_fore', 'a_c_r_per_fore', 'a_m_r_amt_fore', 'a_m_r_per_fore',
      ];
      let dataValues = [
        'control_thresholds_amount_proforma','control_thresholds_percent_proforma','management_thresholds_amount_proforma',
        'management_thresholds_percent_proforma','control_thresholds_amount_regional_proforma','control_thresholds_percent_regional_proforma',
        'management_thresholds_amount_regional_proforma','management_thresholds_percent_regional_proforma','control_thresholds_amount_forecast',
        'control_thresholds_percent_forecast','management_thresholds_amount_forecast','management_thresholds_percent_forecast',
        'control_thresholds_amount_regional_forecast','control_thresholds_percent_regional_forecast','management_thresholds_amount_regional_forecast',
        'management_thresholds_percent_regional_forecast'
      ]
      let forecastKeys = [
        'f_c_l_amt_pro', 'f_c_l_per_pro', 'f_m_l_amt_pro', 'f_m_l_per_pro',
        'f_c_r_amt_pro', 'f_c_r_per_pro', 'f_m_r_amt_pro', 'f_m_r_per_pro',
        'f_c_l_amt_fore', 'f_c_l_per_fore', 'f_m_l_amt_fore', 'f_m_l_per_fore',
        'f_c_r_amt_fore', 'f_c_r_per_fore', 'f_m_r_amt_fore', 'f_m_r_per_fore'
      ];
      let periodList = this.addeditrudata?.periods?.map(item => item.split('-').reverse().join('-')) || null;
      this.addeditrudata.periods = periodList
      let newdata = {
        cycle: this.cycle,
        periods: this.addeditrudata.periods, 
        reportingunit: this.addeditrudata._id || '',
        multiplematerialities:true
      };
      if(this.ruleveledit.enable){
        newdata.threshold = {
          control_thresholds_enable: this.addeditrudata.control_thresholds_enable || false,
          management_thresholds_enable: this.addeditrudata.management_thresholds_enable || false,
          control_thresholds_regional_enable: this.addeditrudata.control_thresholds_regional_enable || false,
          management_thresholds_regional_enable: this.addeditrudata.management_thresholds_regional_enable || false,
        }
      }
      if(this.addeditrudata.__type === "rufile") {
        this.bulksubmitruloading = true;
        newdata.type = "filematerialityrulevel";
        newdata.bulk = true;
        let ndt = [];
        newdata.reportingunit = [];
        let isactualdata = false;
        let isforecastdata = false;
        // console.log(this.bulksubmitrufiledata);
        for (const i of this.bulksubmitrufiledata) {
          let actual = {};
          let forecast = {};
          let td = {};
          td.rucode = this.rucodeidmap[i.rucode];
          newdata.reportingunit.push(td.rucode);
          td.period= i.period
          actualKeys.forEach((value,index)=>{
            if(Object.prototype.hasOwnProperty.call(i, value)){
              actual[dataValues[index]] = i[value] ?? 0;
              isactualdata = true
            } 
          });
          forecastKeys.forEach((value,index)=>{
            if(Object.prototype.hasOwnProperty.call(i, value)){ 
              forecast[dataValues[index]] = i[value] ?? 0;
              isforecastdata = true
            }
          });
          if(isactualdata && isforecastdata) {
            newdata.cycle = "Both"
          }else if(isactualdata) {
            newdata.cycle = "Actual"
          }else if(isforecastdata){
            newdata.cycle = "Forecast"
          }
          td.actual = actual;
          td.forecast = forecast;
          ndt.push(td);
        }
        newdata.materiality = ndt;
      }
      else if(this.addeditrudata.__type === "glfile") {
        this.bulksubmitglloading = true;
        newdata.type = "filematerialitygllevel";
        newdata.bulk = true;
        let ndt = [];
        let rudetails = {};
        let isactualdata = false;
        let isforecastdata = false;
        for (const i of this.bulksubmitglfiledata) {
          let actual = {};
          let forecast = {};
          let td = {};
          // td.rucode = this.rucodeidmap[i.rucode];
          td.glcode = this.glcodeidmap[i.glcode];
          td.period= i.period
          actualKeys.forEach((value,index)=>{
            if(Object.prototype.hasOwnProperty.call(i, value)){
              actual[dataValues[index]] = i[value] ?? 0;
              isactualdata = true
            }
          });
          forecastKeys.forEach((value,index)=>{
            if(Object.prototype.hasOwnProperty.call(i, value)){
              forecast[dataValues[index]] = i[value] ?? 0;
              isforecastdata = true;
            }
          });
          if(isactualdata && isforecastdata) {
            newdata.cycle = "Both"
          }else if(isactualdata) {
            newdata.cycle = "Actual"
          }else if(isforecastdata){
            newdata.cycle = "Forecast"
          }
          td.actual = actual;
          td.forecast = forecast;
          rudetails[this.rucodeidmap[i.rucode]] = rudetails[this.rucodeidmap[i.rucode]] || [];
          rudetails[this.rucodeidmap[i.rucode]].push(td);
        }
        newdata.reportingunit = [];
        for (const k in rudetails) {
          if (Object.hasOwnProperty.call(rudetails, k)) {
            const el = rudetails[k];
            ndt.push({rucode: k, data: el});
            newdata.reportingunit.push(k);
          }
        }
        newdata.materiality = ndt;
      }
      else if(this.singleupdatetype){
        newdata.type = "materialitygllevel";
        if(this.bulksubmit) {
          newdata.bulk = true;
          newdata.type = "bulkmaterialitygllevel";
        }
        let ndt = [];
        let uniqueeditablegls = Array.from(new Set(this.editablegls.proforma.concat(this.editablegls.forecast)));

        for (const i of uniqueeditablegls) {
          Object.keys(this.addeditgldata[i]).forEach(key => {
            if (this.addeditgldata[i][key] === 0.001) {
              delete this.addeditgldata[i][key];
            }
          });
          let td = {...(this.addeditgldata[i] || {})}
          td.glcode = i;
          ndt.push(td);
        }
        newdata.materiality = ndt;
      }else{
        newdata.type = "materialityrulevel";
        newdata.materiality = {}
        console.log(this.addeditrudata)
        if(this.bulksubmit) {
          newdata.bulk = true;      
          newdata.type = "bulkmaterialityrulevel";
        }
        if(this.multipleMaterialityToggle && ['materialityrulevel','bulkmaterialityrulevel'].indexOf(newdata.type) !== -1){
          newdata['materiality']= this.formattedRUData
          this.addeditrudata.periods.forEach((period)=>{
            dataValues.forEach(key=>{
              if(newdata.materiality[period]?.[key] && newdata.materiality[period][key] === 0.001){
                delete newdata.materiality[period][key];
              }
            })
            if(Object.entries(newdata.materiality[period]).length <= 2){
              delete newdata.materiality[period];
            }
          })
          newdata.multiplematerialities=true
        }else if(!this.multipleMaterialityToggle && ['materialityrulevel','bulkmaterialityrulevel'].indexOf(newdata.type) !== -1){
          let data ={}
          newdata["materiality"] = {}
          dataValues.forEach(key=>{
            newdata['materiality'][key] = this.addeditrudata[key] ?? 0.001
          })
          dataValues.forEach(key=>{
          if(newdata.materiality[key] === 0.001){
            delete newdata.materiality[key];
          }
          });
          this.addeditrudata.periods.forEach((period) => {
            data = {
              ...data,
              [period]: newdata.materiality // Use computed property name to set `period` as key
            };
          });
          newdata.materiality = data
          newdata.multiplematerialities=false
        }
        else{
          dataValues.forEach(key=>{
          if(newdata.materiality[key] === 0.001){
            delete newdata.materiality[key];
          }
        }
      )}
      }
      this.axios.post("/v2/varianceanalysis/materiality/saveupdateforapproval", {data: newdata}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Update request raised for approval");
          this.addeditrudialog = false;
          this.bulksubmitrudialog = false;
          this.bulksubmitgldialog = false;
        }else throw new Error (dt.data.message || "Error raising change request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditruloading = false;
        this.bulksubmitruloading = false;
        this.bulksubmitglloading = false;
        this.getPendingApprovalData();
      })
    },
    generateFormattedGLList(dt,ismulti=false){
      let ArrayTemp = [];
      this.addeditgldata = {};
      this.addeditglerror = {};
      (this.gllist["primary"] || []).forEach((i) => {
        this.addeditgldata[i._id] = this.getFormatedGLData(dt[i._id],ismulti);
        this.addeditglerror[i._id] = {};
        ArrayTemp.push(i);
        // this.editablegls.proforma.push(i._id);
        // this.editablegls.forecast.push(i._id);
        (this.gllist[i.code] || []).forEach((ii) => {
          ii.__child = true;
          this.addeditgldata[ii._id] = this.getFormatedGLData(dt[ii._id],ismulti);
          this.addeditglerror[ii._id] = {};
          // this.editablegls.proforma.push(ii._id);
          // this.editablegls.forecast.push(ii._id);
          ArrayTemp.push(ii);
        });
      });
      this.addeditgldatacopy = JSON.parse(JSON.stringify(this.addeditgldata));
      console.log(this.addeditgldata);
      return ArrayTemp;
    },
    getFormatedGLData(i = {}, ismulti=false){
      const thresholdKeys = [
            'control_thresholds_amount_proforma', 'control_thresholds_percent_proforma',
            'management_thresholds_amount_proforma', 'management_thresholds_percent_proforma',
            'control_thresholds_amount_regional_proforma', 'control_thresholds_percent_regional_proforma',
            'management_thresholds_amount_regional_proforma', 'management_thresholds_percent_regional_proforma',
            'control_thresholds_amount_forecast', 'control_thresholds_percent_forecast',
            'management_thresholds_amount_forecast', 'management_thresholds_percent_forecast',
            'control_thresholds_amount_regional_forecast', 'control_thresholds_percent_regional_forecast',
            'management_thresholds_amount_regional_forecast', 'management_thresholds_percent_regional_forecast'
          ];
      let data = {}
      if(ismulti){
        thresholdKeys.forEach(key => {
            data[key] = 0.001;
          });
      }else{
        thresholdKeys.forEach(key => {
          if(this.isViewMode){
            data[key] = i[key] || 0;
          }else{
            data[key] = 0.001;
          }
        });
        // return {
            
        //   // floor_control_thresholds_amount_proforma : i?.floor_control_thresholds_amount_proforma || 0,
        //   // floor_management_thresholds_amount_proforma : i?.floor_management_thresholds_amount_proforma || 0,
        //   // floor_control_thresholds_amount_regional_proforma : i?.floor_control_thresholds_amount_regional_proforma || 0,
        //   // floor_management_thresholds_amount_regional_proforma : i?.floor_management_thresholds_amount_regional_proforma || 0,
        //   // floor_control_thresholds_amount_forecast : i?.floor_control_thresholds_amount_forecast || 0,
        //   // floor_management_thresholds_amount_forecast : i?.floor_management_thresholds_amount_forecast || 0,
        //   // floor_control_thresholds_amount_regional_forecast : i?.floor_control_thresholds_amount_regional_forecast || 0,
        //   // floor_management_thresholds_amount_regional_forecast : i?.floor_management_thresholds_amount_regional_forecast || 0,

        // }
      }
      data['control_thresholds_percent_proforma'] = 999999
      data['control_thresholds_percent_regional_proforma'] = 999999
      return data;

    },
    reorder(dt, key){
      return dt.sort(function(a,b){return a[key] - b[key];});
    },
    readBulkSubmitFile(key) {
      this.emptyRuCode=false
      let file = null;
      if(key === "rufile") file = this.bulksubmitrufile;
      else if(key === "glfile") file = this.bulksubmitglfile;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = [];
        if (key === "rufile") headings = [
                                'RU Code', 'Name of Reporting Unit','Period(MM-YYYYY)', 'LC Amt', 'LC%', 'LM Amt', 'LM %',
                                'RC Amt', 'RC %', 'RM Amt', 'RM %', 'LC Amt', 'LC%', 'LM Amt', 'LM %',
                                'RC Amt', 'RC %', 'RM Amt', 'RM %', 'LC Amt', 'LC%', 'LM Amt', 'LM %',
                                'RC Amt', 'RC %', 'RM Amt', 'RM %', 'LC Amt', 'LC%', 'LM Amt', 'LM %',
                                'RC Amt', 'RC %', 'RM Amt', 'RM %'
                              ];
        else if (key === "glfile") headings = ['RU Code', 'Name of Reporting Unit', 'GL Code', 'GL Description',  
                                    'Period(MM-YYYYY)',"LC Amt", "LC%", "LM Amt", "LM %", "RC Amt", "RC %", "RM Amt", "RM %", "LC Amt",
                                    "LC%", "LM Amt", "LM %", "RC Amt", "RC %", "RM Amt", "RM %", "LC Amt", "LC%", 
                                    "LM Amt", "LM %", "RC Amt", "RC %", "RM Amt", "RM %", "LC Amt", "LC%", "LM Amt", 
                                    "LM %","RC Amt", "RC %", "RM Amt", "RM %"];
        this.bulksubmitruloading = true;
        this.bulksubmitglloading = true;
        this.fileprocessing[key] = 1;
        this.bulksubmitrufiledata = [];
        this.bulksubmitrufileerror = [];
        let visitedRuCodes = [];
        let visitedGlCodes = {};
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[2] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            this.bulksubmitrufileerrorflag = false;
            this.bulksubmitglfileerrorflag = false;
            let invalidData = {};
            for (let i = 2; i < dt.length; i++) {
              if (i > 2) {
                invalidData = { 'actual_local_proforma_error_data':0,'actual_regional_proforma_error_data':0,'actual_local_forecast_error_data':0,'actual_regional_forecast_error_data':0,
                     'forecast_local_proforma_error_data':0,'forecast_regional_proforma_error_data':0,'forecast_local_forecast_error_data':0,'forecast_regional_forecast_error_data':0,'general':0};
                const el = dt[i];
                let errorFormatData = {}
                if(el.join("") !== ""){
                  let errors = {};
                  let startindex = 3;
                  let rowdata = {}
                  if(el[0]=== 0){
                    this.emptyRuCode=true
                  }
                  rowdata = {"rucode": el[0]?.trim()||"", "runame": this.rucodenamemap[el[0]?.trim()] || el[1]?.trim() || ""};
                  if (visitedRuCodes.indexOf(rowdata.rucode+el[2]) !== -1 && key==="rufile") {errors.rucode = "Duplicated RU Code in uploaded data";invalidData.general++}
                  if (!this.rucodenamemap[rowdata.rucode]) {errors.rucode = "RU Code not found";invalidData.general++}
                  if (rowdata.rucode === ""){errors.rucode = "Missing RU Code",invalidData.general++}
                  if(key === 'rufile'){
                    visitedRuCodes.push(rowdata.rucode+el[2])
                    rowdata.period=el[2]
                  }
                  
                  if(key === "glfile") {
                    startindex = 5;
                    rowdata.glcode = el[2];
                    rowdata.glname = this.glcodenamemap[el[2]] || "";
                    if (!visitedGlCodes[rowdata.rucode] && typeof rowdata.glcode !== undefined) {
                        visitedGlCodes[rowdata.rucode] = [];
                    }
                    if (!this.glcodenamemap[rowdata.glcode]) {errors["glcode"] = "GL Code not found";invalidData.general++}
                    if (typeof rowdata.glcode === undefined || rowdata.glcode === ""){errors.glcode = "Missing GL Code";invalidData.general++}
                    rowdata.period= el[4] || "";
                  }
                  if(!this.isValidDateFormat(rowdata.period)) {
                    errors.period = "Invalid period";
                    invalidData.general++
                    this.bulksubmitrufileerrorflag = true;
                    this.bulksubmitglfileerrorflag = true;
                  }
                  let [month, year] = rowdata.period.split("-");
                  let [fyStartyear, fyStartmonth] = this.minDate.split("-");
                  let [fyEndyear, fyEndmonth ] = this.maxDate.split("-");
                  const date = new Date(year, month - 1, 1); // month1 - 1 because months are zero-indexed in JavaScript
                  const fyStartDate = new Date(fyStartyear, fyStartmonth - 1, 1);
                  const fyEndDate = new Date(fyEndyear, fyEndmonth - 1, 1);
                  if(date < fyStartDate || date > fyEndDate){
                    errors.period = "Period is not within the Financial Year";
                    invalidData.general++;
                    this.bulksubmitrufileerrorflag = true;
                    this.bulksubmitglfileerrorflag = true;
                  }
                  if (key==='glfile' && visitedGlCodes[rowdata.rucode] && visitedGlCodes[rowdata.rucode].indexOf(rowdata.glcode+month+year) !== -1) {errors.glcode = "Duplicated GL Code in uploaded data";invalidData.general++}
                  if(typeof rowdata.glcode !== undefined && key==='glfile'){
                    visitedGlCodes[rowdata.rucode].push(rowdata.glcode+month+year)
                  }
                  const actualLocal_proforma = [
                    'a_c_l_amt_pro', 'a_c_l_per_pro', 'a_m_l_amt_pro', 'a_m_l_per_pro'
                  ];
                  const actualRegional_proforma = [
                    'a_c_r_amt_pro', 'a_c_r_per_pro', 'a_m_r_amt_pro', 'a_m_r_per_pro'
                  ];
                  const actualLocal_forecast = [
                    'a_c_l_amt_fore', 'a_c_l_per_fore', 'a_m_l_amt_fore', 'a_m_l_per_fore'
                  ];
                  const actualRegional_forecast = [
                    'a_c_r_amt_fore', 'a_c_r_per_fore', 'a_m_r_amt_fore', 'a_m_r_per_fore'
                  ];
                  const actualFields = actualLocal_proforma.concat(actualRegional_proforma.concat(actualLocal_forecast.concat(actualRegional_forecast.concat())))
                  const forecastLocal_proforma = [
                    'f_c_l_amt_pro', 'f_c_l_per_pro', 'f_m_l_amt_pro', 'f_m_l_per_pro'
                  ];
                  const forecastRegional_proforma = [
                    'f_c_r_amt_pro', 'f_c_r_per_pro', 'f_m_r_amt_pro', 'f_m_r_per_pro'
                  ];
                  const forecastLocal_forecast = [
                    'f_c_l_amt_fore', 'f_c_l_per_fore', 'f_m_l_amt_fore', 'f_m_l_per_fore'
                  ];
                  const forecastRegional_forecast = [
                    'f_c_r_amt_fore', 'f_c_r_per_fore', 'f_m_r_amt_fore', 'f_m_r_per_fore'
                  ];
                  const forecastFields = forecastLocal_proforma.concat(forecastRegional_proforma.concat(forecastLocal_forecast.concat(forecastRegional_forecast.concat())))
                  const ignoredFields = ['a_c_l_per_pro', 'a_c_r_per_pro', 'f_c_l_per_pro', 'f_c_r_per_pro'];
                  let errorDataType = 'actual_local_error_data'
                  actualFields.concat(forecastFields).forEach((field, index) => {
                    
                    if(actualLocal_proforma.indexOf(field) > -1){
                      errorDataType = 'actual_local_proforma_error_data'
                    }
                    else if(actualRegional_proforma.indexOf(field) > -1){
                      errorDataType = 'actual_regional_proforma_error_data'
                    }
                    else if(actualLocal_forecast.indexOf(field) > -1){
                      errorDataType = 'actual_local_forecast_error_data'
                    }
                    else if(actualRegional_forecast.indexOf(field) > -1){
                      errorDataType = 'actual_regional_forecast_error_data'
                    }
                    else if(forecastLocal_proforma.indexOf(field) > -1){
                      errorDataType = 'forecast_local_proforma_error_data'
                    }
                    else if(forecastRegional_proforma.indexOf(field) > -1){
                      errorDataType = 'forecast_regional_proforma_error_data'
                    }
                    else if(forecastLocal_forecast.indexOf(field) > -1){
                      errorDataType = 'forecast_local_forecast_error_data'
                    }
                    else if(forecastRegional_forecast.indexOf(field) > -1){
                      errorDataType = 'forecast_regional_forecast_error_data'
                    }
                    if (typeof el[startindex + index] === 'number' && !isNaN(Number(el[startindex + index]))) {
                      rowdata[field] = Number(el[startindex + index]);
                    } else {
                      if(ignoredFields.indexOf(field) === -1){
                        invalidData[errorDataType]++;
                        errors[field] = "Missing Values";
                      }
                    }
                  });
                  filedata.push(rowdata);
                  if (invalidData.actual_local_proforma_error_data === (actualLocal_proforma.length-1) && invalidData.actual_regional_proforma_error_data === (actualRegional_proforma.length-1) && invalidData.forecast_local_proforma_error_data === (forecastLocal_proforma.length -1) && invalidData.forecast_regional_proforma_error_data === (forecastRegional_proforma.length-1) && invalidData.actual_local_forecast_error_data === (actualLocal_forecast.length ) && invalidData.actual_regional_forecast_error_data === (actualRegional_forecast.length) && invalidData.forecast_local_forecast_error_data === (forecastLocal_forecast.length) && invalidData.forecast_regional_forecast_error_data === (forecastRegional_proforma.length)){
                    errordata.push(errors)
                    if(invalidData.general !=0){
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    }
                    
                  }else{
                    if(invalidData.actual_local_proforma_error_data !== actualLocal_proforma.length-1 && invalidData.actual_local_proforma_error_data !==0){
                      actualLocal_proforma.forEach((field)=>{
                          errorFormatData[field] = errors[field] || null;
                      })
                      rowdata["a_c_l_per_pro"] = 999999;
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    } else {
                      if(invalidData.actual_local_proforma_error_data !== (actualLocal_proforma.length-1)){
                        rowdata["a_c_l_per_pro"] = 999999;
                      }
                    }
                    if (invalidData.actual_regional_proforma_error_data !== (actualRegional_proforma.length-1) && invalidData.actual_regional_proforma_error_data !== 0) {
                      actualRegional_proforma.forEach((field) => {
                          errorFormatData[field] = errors[field] || null;
                      })
                      rowdata["a_c_r_per_pro"] = 999999;
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    } else {
                      if(invalidData.actual_regional_proforma_error_data !== (actualRegional_proforma.length-1)){
                        rowdata["a_c_r_per_pro"] = 999999;
                      }
                    }
                    if (invalidData.forecast_local_proforma_error_data !== (forecastLocal_proforma.length-1) && invalidData.forecast_local_proforma_error_data !== 0) {
                      forecastLocal_proforma.forEach((field)=>{
                          errorFormatData[field] = errors[field] || null;
                      })
                      rowdata["f_c_l_per_pro"] = 999999;
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    }else {
                      if(invalidData.forecast_local_proforma_error_data !== (forecastLocal_proforma.length-1)){
                        rowdata["f_c_l_per_pro"] = 999999;
                      }
                    }
                    if (invalidData.forecast_regional_proforma_error_data !== (forecastRegional_proforma.length-1) && invalidData.forecast_regional_proforma_error_data !== 0) {
                      forecastRegional_proforma.forEach((field) => {
                          errorFormatData[field] = errors[field] || null;
                      })
                      rowdata["f_c_r_per_pro"] = 999999;
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    }else {
                      if(invalidData.forecast_regional_proforma_error_data !== (forecastRegional_proforma.length-1)){
                        rowdata["f_c_r_per_pro"] = 999999;
                      }
                    }
                    if(invalidData.actual_local_forecast_error_data !== actualLocal_forecast.length && invalidData.actual_local_forecast_error_data !== 0){
                      actualLocal_forecast.forEach((field)=>{
                          errorFormatData[field] = errors[field] || null;
                      })
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    } 
                    if (invalidData.actual_regional_forecast_error_data !== (actualRegional_forecast.length) && invalidData.actual_regional_forecast_error_data !== 0) {
                      actualRegional_forecast.forEach((field) => {
                        errorFormatData[field] = errors[field] || null;
                      })
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    } 
                    if (invalidData.forecast_local_forecast_error_data !== (forecastLocal_forecast.length) && invalidData.forecast_local_forecast_error_data !== 0) {
                      forecastLocal_forecast.forEach((field)=>{
                        errorFormatData[field] = errors[field] || null;
                      })
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    }
                    if (invalidData.forecast_regional_forecast_error_data !== (forecastRegional_forecast.length) && invalidData.forecast_regional_forecast_error_data !== 0) {
                      forecastRegional_forecast.forEach((field) => {
                          errorFormatData[field] = errors[field] || null;
                      })
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    } 
                    if(invalidData.general !=0){
                      console.log(errors)
                      errorFormatData.rucode = errors.rucode || null; 
                      errorFormatData.glcode = errors.glcode || null; 
                      errorFormatData.period = errors.period || null; 
                      this.bulksubmitrufileerrorflag = true;
                      this.bulksubmitglfileerrorflag = true;
                    }
                    
                    errordata.push(errorFormatData);
                  }
                }
              }
            }
            console.log(errordata)
            if(key === "rufile") {
              this.bulksubmitrufiledata = filedata;
              this.bulksubmitrufileerror = errordata;
              if(this.bulksubmitrufileerrorflag){
                this.$store.commit('sbError',"There are few values missing")
              }
            }
            else if(key === "glfile") {
              this.bulksubmitglfiledata = filedata;
              this.bulksubmitglfileerror = errordata;
              if (this.bulksubmitglfileerrorflag) {
                this.$store.commit('sbError', "There are few values missing")
              }
            }
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.bulksubmitrufile = null;
          this.bulksubmitglfile = null;
          this.fileprocessing[key] = 0;
          this.bulksubmitruloading = false;
          this.bulksubmitglloading = false;
        });
      }
    },
    isValidDateFormat(dateString) {
      const mmYyyyRegex = /^(0[1-9]|1[0-2])-(20\d{2})$/; 
      return mmYyyyRegex.test(dateString);
    },
    toISOConverter(rawDate) {
      try {
        const date = new Date(rawDate);

        // Extract date components
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // January is 0
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format the date as desired (e.g., YYYY-MM-DD HH:mm:ss)
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        return formattedDate
      } catch (error) {
        console.error("Error:", error.message);
      }

    },
    cancelEditItem(id,type='proforma') {
      const index = this.editablegls[type].indexOf(id);
      // this.addeditgldatacopy = this.addedgldatacopy[0];
      if (index === -1) {
        this.editablegls[type].push(id);
      } else {
        this.editablegls[type].splice(index, 1);
        if(type === 'proforma'){
          this.addeditgldata[id].control_thresholds_amount_proforma = this.addeditgldatacopy[id]?.control_thresholds_amount_proforma || 0.001;
          this.addeditgldata[id].control_thresholds_percent_proforma = this.addeditgldatacopy[id]?.control_thresholds_percent_proforma || 0.001;
          //this.addeditgldata[id].floor_control_thresholds_amount_proforma = this.addeditgldatacopy[id]?.floor_control_thresholds_amount_proforma || 0;
          this.addeditgldata[id].management_thresholds_amount_proforma = this.addeditgldatacopy[id]?.management_thresholds_amount_proforma || 0.001;
          this.addeditgldata[id].management_thresholds_percent_proforma = this.addeditgldatacopy[id]?.management_thresholds_percent_proforma || 0.001;
          //this.addeditgldata[id].floor_management_thresholds_amount_proforma = this.addeditgldatacopy[id]?.floor_management_thresholds_amount_proforma || 0;
          this.addeditgldata[id].control_thresholds_amount_regional_proforma = this.addeditgldatacopy[id]?.control_thresholds_amount_regional_proforma || 0.001;
          this.addeditgldata[id].control_thresholds_percent_regional_proforma = this.addeditgldatacopy[id]?.control_thresholds_percent_regional_proforma || 0.001;
          //this.addeditgldata[id].floor_control_thresholds_amount_regional_proforma = this.addeditgldatacopy[id]?.floor_control_thresholds_amount_regional_proforma || 0;
          this.addeditgldata[id].management_thresholds_amount_regional_proforma = this.addeditgldatacopy[id]?.management_thresholds_amount_regional_proforma || 0.001;
          this.addeditgldata[id].management_thresholds_percent_regional_proforma = this.addeditgldatacopy[id]?.management_thresholds_percent_regional_proforma || 0.001;
          //this.addeditgldata[id].floor_management_thresholds_amount_regional_proforma = this.addeditgldatacopy[id]?.floor_management_thresholds_amount_regional_proforma || 0;
        }
        else if(type === 'forecast'){
          this.addeditgldata[id].control_thresholds_amount_forecast = this.addeditgldatacopy[id].control_thresholds_amount_forecast || 0.001;
          this.addeditgldata[id].control_thresholds_percent_forecast = this.addeditgldatacopy[id]?.control_thresholds_percent_forecast || 0.001;
          //this.addeditgldata[id].floor_control_thresholds_amount_forecast = this.addeditgldatacopy[id]?.floor_control_thresholds_amount_forecast;
          this.addeditgldata[id].management_thresholds_amount_forecast = this.addeditgldatacopy[id]?.management_thresholds_amount_forecast || 0.001;
          this.addeditgldata[id].management_thresholds_percent_forecast = this.addeditgldatacopy[id]?.management_thresholds_percent_forecast || 0.001;
          //this.addeditgldata[id].floor_management_thresholds_amount_forecast = this.addeditgldatacopy[id]?.floor_management_thresholds_amount_forecast;
          this.addeditgldata[id].control_thresholds_amount_regional_forecast = this.addeditgldatacopy[id]?.control_thresholds_amount_regional_forecast || 0.001;
          this.addeditgldata[id].control_thresholds_percent_regional_forecast = this.addeditgldatacopy[id]?.control_thresholds_percent_regional_forecast || 0.001;
          //this.addeditgldata[id].floor_control_thresholds_amount_regional_forecast = this.addeditgldatacopy[id]?.floor_control_thresholds_amount_regional_forecast;
          this.addeditgldata[id].management_thresholds_amount_regional_forecast = this.addeditgldatacopy[id]?.management_thresholds_amount_regional_forecast || 0.001;
          this.addeditgldata[id].management_thresholds_percent_regional_forecast = this.addeditgldatacopy[id]?.management_thresholds_percent_regional_forecast || 0.001;
          //this.addeditgldata[id].floor_management_thresholds_amount_regional_forecast = this.addeditgldatacopy[id]?.floor_management_thresholds_amount_regional_forecast;
        }
      }
    },
    removeMonth(month) {
      this.addeditrudata.periods = this.addeditrudata.periods.filter((m) => m !== month);
      delete this.formattedRUData[month.split('-').reverse().join('-')];
    },
  },
  computed: {
  },
  watch: {
    'addeditrudata.periods': {
      async handler(newVal=[]) {
        const thresholdKeys = [
            'control_thresholds_amount_proforma', 'control_thresholds_percent_proforma',
            'management_thresholds_amount_proforma', 'management_thresholds_percent_proforma',
            'control_thresholds_amount_regional_proforma', 'control_thresholds_percent_regional_proforma',
            'management_thresholds_amount_regional_proforma', 'management_thresholds_percent_regional_proforma',
            'control_thresholds_amount_forecast', 'control_thresholds_percent_forecast',
            'management_thresholds_amount_forecast', 'management_thresholds_percent_forecast',
            'control_thresholds_amount_regional_forecast', 'control_thresholds_percent_regional_forecast',
            'management_thresholds_amount_regional_forecast', 'management_thresholds_percent_regional_forecast'
          ];
          
          newVal.forEach(period=>{
            let formatPeriod = period.split('-').reverse().join('-');
            if(typeof this.formattedRUData[formatPeriod] !== 'undefined'){
              this.formattedRUData[formatPeriod] = this.formattedRUData?.[formatPeriod] || {};
            }
            else{
              this.formattedRUData[formatPeriod] = {};
              this.formattedRUErrorData[formatPeriod] = {};
              thresholdKeys.forEach(key => {
                  this.formattedRUData[formatPeriod][key] = 0.001;
              });
              this.formattedRUData[formatPeriod]['control_thresholds_percent_proforma'] = 999999
              this.formattedRUData[formatPeriod]['control_thresholds_percent_regional_proforma'] = 999999
            }
          })
          Object.entries(this.formattedRUData).forEach(([key]) => {
            if(!newVal.includes(key.split('-').reverse().join('-'))){
              delete this.formattedRUData[key.split('-').reverse().join('-')];
              delete this.formattedRUErrorData[key.split('-').reverse().join('-')];
            }
          });
      },
      deep: true
    },
    singleupdatetype:{
      handler(newVal){
        if(newVal === true){
          this.multipleMaterialityToggle=false
        }
      }
    }
  }
}
</script>

<style type="scss" scoped>
.commentary_materiality_actual{
  font-color: #7A9D54
}
.selected-months {
  margin-top: 20px;
}
.selected-months ul {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  padding: 0;
  margin: 0;
  list-style-type: none; /* Remove default list styling */
}
.month-item {
  display: flex;
  align-items: center;
  margin: 1px 2px; /* Spacing around items */
  padding: 0px 2px;
  background-color: #e0f7fa; /* Background color for items */
  border-radius: 5px;
  font-size: 0.7em;
}
.custom-tooltip.tooltip-bottom::before{
      
  left:70% !important;               
  /* Additional customizations here */
}
</style>

